/* --------------------------------

LineIconsPro Regular Web Font
Author: lineicons.com

-------------------------------- */
@import "variables";
@import "mixins";

@font-face {
  font-family: 'LineIconsPro Regular';
  src: url('#{$lnir-font-path}/LineIconsPro Regular.eot');
  src: url('#{$lnir-font-path}/LineIconsPro Regular.eot') format('embedded-opentype'),
    url('#{$lnir-font-path}/LineIconsPro Regular.woff2') format('woff2'),
    url('#{$lnir-font-path}/LineIconsPro Regular.woff') format('woff'),
    url('#{$lnir-font-path}/LineIconsPro Regular.ttf') format('truetype'),
    url('#{$lnir-font-path}/LineIconsPro Regular.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

/*------------------------
	base class definition
-------------------------*/



.lnir{
  @include lnir()
}

/*------------------------
  change icon size
-------------------------*/

/* relative units */
.lnir-sm {
  font-size: 0.8em;
}
.lnir-lg {
  font-size: 1.2em;
}
/* absolute units */
.lnir-16 {
  font-size: 16px;
}
.lnir-32 {
  font-size: 32px;
}

/*----------------------------------
  add a square/circle background
-----------------------------------*/

.lnir-bg-square,
.lnir-bg-circle {
  padding: 0.35em;
  background-color: $lnir-background-color;
}

.lnir-bg-circle {
  border-radius: 50%;
}

/*------------------------------------
  use icons as list item markers
-------------------------------------*/

.lnir-ul {
  padding-left: 0;
  list-style-type: none;

  > li {
    display: flex;
    align-items: flex-start;
    line-height: 1.4;
  }

  > li > .lnir {
    margin-right: $lnir-li-margin-right;
    line-height: inherit;
  }
}

/*------------------------
  spinning icons
-------------------------*/

.lnir-is-spinning {
  -webkit-animation: lnir-spin 2s infinite linear;
  -moz-animation: lnir-spin 2s infinite linear;
  animation: lnir-spin 2s infinite linear;
}

@-webkit-keyframes lnir-spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@-moz-keyframes lnir-spin {
  0% {
    -moz-transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(360deg);
  }
}
@keyframes lnir-spin {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/*------------------------
  rotated/flipped icons
-------------------------*/

.lnir-rotate-90  {
	@include lnir-rotate(90deg, 1);
}

.lnir-rotate-180 {
	@include lnir-rotate(180deg, 2);
}

.lnir-rotate-270 {
	@include lnir-rotate(270deg, 3);
}

.lnir-flip-y {
	@include lnir-flip(-1, 1, 0);
}
.lnir-flip-x {
	@include lnir-flip(1, -1, 2);
}

@import "icons";
