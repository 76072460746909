@import "../../variable";

.playGroup .action a.active, .player .songInfo .actionLink a.active{ color: $primary}
.playGroup .action .active, .player .songInfo .actionLink .active{ color: $primary}
.topView{
    .play-icon{
        display: none;
    }
    .count{
        .ico{
            border-width: 6px 0 6px 11px;
        }
        .pause-icon{
            width: 11px;
            height: 12px;
            position: relative;
            display: block;
            &:before {
                width: 3px;
                background-color: #f04d25;
                height: 12px;
                content: "";
                position: absolute;
                left: 0px;
            }&:after {
                position: absolute;
                width: 3px;
                background-color: #f04d25;
                height: 12px;
                content: "";
                right: 0px;
            }
        }
    }&.active{
        .pause-icon{
            display: inline-block;
        }
    }
    &:hover{
        .play-icon{
            display: inline-block;
        }
        .count > span{
            display: none;
        }
    }
}
.topView:hover{
    .count{
        .play-icon{
            display: inline-block;
        }
    }
}
