@import "../../variable";



.searchBox.input-group{
    .input-group-prepend{
        padding-top: 3px;
        .input-group-text{
            padding-right: 0;
            padding-left: 0;
            background-color: transparent;
            border: 0 none;
        }
    }
    .form-control{
        border: 0  none;
        font-size:16px;
        min-width: 260px;
    }
}

.userOption.dropdown{
    .btn{
        background-color: transparent;
        border: 0 none;
        padding:0;
        &::after{
            border:0 none;
            content:$lnir-chevron-down;
            @include lnir();
            color: $darkGray;
            position: relative;
            top: 5px;
            margin-left:12px;
        }
        &:hover,&:focus,&:active,&:focus-visible{
            box-shadow: none;
            background-color: transparent;
            outline: none;
        }
    }
    .img{
        width:46px;
        height:46px;
        background-color:#333333;
        border-radius:100%;
        display: inline-block;
        vertical-align: middle;
    }
}

.themeMode{
    .custom-control.custom-switch {
        padding-left:0px;
        padding-right:82px;
        position: relative;
        .custom-control-label{
            &::before{
                left: auto;
                right: -82px;
                width:60px;
                height:32px;
                border-radius:16px;
                top: 50%;
                margin-top: -16px;
                background-color:$primary;
                border:0 none;
                content:$lnir-sun $lnir-moon;
                @include lnir();
                letter-spacing: 11px;
                line-height: 32px;
                padding-left: 9px;
                color: #fff;
            }
            &::after{
                width:26px;
                height:26px;
                border-radius:13px;
                top: 50%;
                margin-top: -13px;
                left:auto;
                right:-51px;
                background-color:#ffffff;
                z-index:9;
                content:$lnir-sun;
                @include lnir();
                text-align: center;
                line-height:26px;
            }
        }
        .custom-control-input:checked ~ .custom-control-label::after{
            right:-67px;            
            content:$lnir-moon;
        }
    }
}

.cartLink{
    i.lnir-package{
        font-size:20px;
        margin-right:15px;
        .count{
            background-color: $primary;
            color: #fff;
            font-size: 12px;
            padding: 3px;
            border-radius: 9px;
            min-width: 18px;
            height: 18px;
            text-align: center;
            font-family: 'cerebri_sanssemibold';
            bottom:-9px;
            right:-9px;
        }
    }
    a{
        color: $darkGray;
    }&:hover, &:visited{
        color: $darkGray
    }
}

.optionSide{
    order:2
}

.menuBtn{
    background-color: #ddd;
    width: 42px;
    height: 42px;
    line-height: 42px;
    text-align: center;
    font-size: 23px;
    line-height: 42px; 
    cursor: pointer;
    i{
        line-height: 42px;
    }
}


@media only screen and (max-width: 576px) {

    .userOption.dropdown .img{
        width:32px; height:32px;
    }
    .userOption.dropdown .btn::after{
        display: none !important;
    }

}