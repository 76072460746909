@import "../../variable";

.player {
  bottom: 0px;
  left: 0px;
  padding: 0 40px;
  right: 0px;
  background-color: #fff;
  z-index: 999;
  height: 101px;
  border-top: 1px solid #d5d5d5;
  .songInfo {
    border-right: 1px solid #e7e7e7;
    padding-right: 60px;
    height: 60px;
    .img{
      position: relative;
      img{
        width: 50px;
        height: 50px;
        border-radius:100%;
      }
    }
    .infoText {
      padding: 0 25px;
      width: 320px;
      padding-right: 20px;
      h5 {
        font-size: 16px;
        color: $dark;
        margin-bottom: 3px;
      }
      .creator {
        color: $lightGray;
        font-size: 13px;
      }
    }
    .actionLink {
      position: relative;
      span {
        display: inline-block;
        margin-left: 10px;
        padding: 5px 15px;
        color: $lightGray;
        &:hover {
          color: $primary;
        }
      }
    }
  }
  .playControl {
    span {
      width: 56px;
      height: 56px;
      background-color: $bright;
      text-align: center;
      line-height: 56px;
      display: inline-block;
      border-radius: 50px;
      margin: 0 9px;
      &.songState {
        background-color: $primary;
        &:hover {
          background-color: #333333;
        }
      }
      &:hover {
        background-color: #f7f7f7;
        i {
          color: $primary;
        }
      }
      &.active {
        i {
          color: $primary;
        }
      }
      i {
        color: $lightGray;
      }
    }
    i.play-icon {
      &::before {
        margin-top: 2px;
        border-width: 10px 0 10px 18px;
        border-color: transparent transparent transparent #ffffff;
      }
    }
    i.pause-icon {
      display: inline-block;
      width: 18px;
      height: 23px;
      border-left: 5px solid #ffffff;
      border-right: 5px solid #ffffff;
      margin-top: 16px;
    }
  }
  .timer {
    font-size: 14px;
  }
  .sound{
    width:40px;
    height:40px;
    text-align: center;
    line-height: 40px;
    border-radius:100%;
    &:hover{
      background-color:#f7f7f7;
    }
    i {
      font-size: 20px;
      color: $lightGray;
    }
  } 
}

// .hidePlayer {
//   width: 0px;
//   height: 0px;
//   overflow: hidden;
// }

.react-jinke-music-player-main{
  display: none;
}

.rangeslider {
  margin: 20px 0;
  position: relative;
  background: #e6e6e6;
  -ms-touch-action: none;
  touch-action: none;
}
.rangeslider,
.rangeslider .rangeslider__fill {
  display: block;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.4);
}
.rangeslider .rangeslider__handle {
  background: #fff;
  border: 1px solid #ccc;
  cursor: pointer;
  display: inline-block;
  position: absolute;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4), 0 -1px 3px rgba(0, 0, 0, 0.4);
}
.rangeslider .rangeslider__handle .rangeslider__active {
  opacity: 1;
}
.rangeslider .rangeslider__handle-tooltip {
  width: 40px;
  height: 40px;
  text-align: center;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.8);
  font-weight: 400;
  font-size: 14px;
  transition: all 0.1s ease-in;
  border-radius: 4px;
  display: inline-block;
  color: #fff;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
}
.rangeslider .rangeslider__handle-tooltip span {
  margin-top: 12px;
  display: inline-block;
  line-height: 100%;
}
.rangeslider .rangeslider__handle-tooltip:after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
}
.rangeslider-horizontal {
  height: 12px;
  border-radius: 10px;
}
.rangeslider-horizontal .rangeslider__fill {
  height: 100%;
  background-color: #7cb342;
  border-radius: 10px;
  top: 0;
}
.rangeslider-horizontal .rangeslider__handle {
  width: 30px;
  height: 30px;
  border-radius: 30px;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
}
.rangeslider-horizontal .rangeslider__handle:after {
  content: " ";
  position: absolute;
  width: 16px;
  height: 16px;
  top: 6px;
  left: 6px;
  border-radius: 50%;
  background-color: #dadada;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4) inset,
    0 -1px 3px rgba(0, 0, 0, 0.4) inset;
}
.rangeslider-horizontal .rangeslider__handle-tooltip {
  top: -55px;
}
.rangeslider-horizontal .rangeslider__handle-tooltip:after {
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid rgba(0, 0, 0, 0.8);
  left: 50%;
  bottom: -8px;
  transform: translate3d(-50%, 0, 0);
}
.rangeslider-vertical {
  margin: 10px auto;
  height: 120px;
  max-width: 5px;
  background-color: transparent;
}
.rangeslider-vertical .rangeslider__fill,
.rangeslider-vertical .rangeslider__handle {
  position: absolute;
}
.rangeslider-vertical .rangeslider__fill {
  width: 100%;
  background-color: #7cb342;
  box-shadow: none;
  bottom: 0;
}
.rangeslider-vertical .rangeslider__handle {
  width: 30px;
  height: 30px;
  left: -13px;
  box-shadow: none;
  border-radius:100%;
}
.rangeslider-vertical .rangeslider__handle-tooltip {
  left: -100%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
}
.rangeslider-vertical .rangeslider__handle-tooltip:after {
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 8px solid rgba(0, 0, 0, 0.8);
  left: 100%;
  top: 12px;
}
.rangeslider-reverse.rangeslider-horizontal .rangeslider__fill {
  right: 0;
}
.rangeslider-reverse.rangeslider-vertical .rangeslider__fill {
  top: 0;
  bottom: inherit;
}
.rangeslider__labels {
  position: relative;
}
.rangeslider-vertical .rangeslider__labels {
  position: relative;
  list-style-type: none;
  margin: 0 0 0 24px;
  padding: 0;
  text-align: left;
  width: 250px;
  height: 100%;
  left:0px;
}
.rangeslider-vertical .rangeslider__labels .rangeslider__label-item {
  position: absolute;
  transform: translate3d(0, -50%, 0);
}
.rangeslider-vertical .rangeslider__labels .rangeslider__label-item::before {
  content: "";
  width: 10px;
  height: 2px;
  background: #000;
  position: absolute;
  left: -14px;
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
}
.rangeslider__labels .rangeslider__label-item {
  position: absolute;
  font-size: 14px;
  cursor: pointer;
  display: inline-block;
  top: 10px;
  transform: translate3d(-50%, 0, 0);
}


.volSlider{
  position: absolute;
  bottom: 100%;
  right: 38px;
  width: 50px;
  background-color: #fff;
  border-radius: 10px 10px 0 0;
  z-index:9999;
  display:none;
  &.active{
    display: block;
  }
}

.volDroper{
  position:fixed;
  width: 100%;
  height: 100%;
  top:0;
  left:0;
  display: none;
  z-index:99;
  &.active{
    display: block;
  }
}


@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.img.animate {
  -webkit-animation: rotating 4s linear infinite;
  -moz-animation: rotating 4s linear infinite;
  -ms-animation: rotating 4s linear infinite;
  -o-animation: rotating 4s linear infinite;
  animation: rotating 4s linear infinite;
}
.playListBox.playListBoxHide{
    display: none;
}
.playListBox{
  position: absolute;
  bottom: 100%;
  background-color:#ffffff;
  right: 0;
  padding:15px;
  width:480px;
  height:300px;
  border: 1px solid #979797;
  .playItem:first-child{
    border-top:0 none;
  }  
}

.playItem{
  padding:10px 0;
  border-top:1px solid #f5f5f5;
  .iconSide{
    padding-right:10px;
  }
  .name{
    font-size:17px;
    color: #333333;
  }
  .singer{
    font-size:13px;
    color:#999999;
  }
}

.playBox{
  width: 36px;
    height: 36px;
    border: 3px solid #ececec;
    border-radius: 100%;
    text-align: center;
    line-height: 30px;
    color: #878787;
}
.wave-outer-width,
.wave-width{ width: 550px;}

@media only screen and (max-width: 1790px) {
  .wave-outer-width,
  .wave-width{ width: 450px;}  
}

@media only screen and (max-width: 1630px) {
  .wave-outer-width,
  .wave-width{ width: 350px;}  
  .player .songInfo .infoText{width:200px;}
}

@media only screen and (max-width: 1440px) {
  .wave-outer-width,
  .wave-width{ width: 300px;}  
  .player .songInfo{padding-right:20px}
}

@media only screen and (max-width: 1330px) {
  .player .songInfo{padding-right:20px}
}

@media only screen and (max-width: 1280px) {
  .wave-outer-width,
  .wave-width{ width:230px;}  
}
@media only screen and (max-width: 1024px) {
  .player .songInfo .infoText{display: none;}
  .player .songInfo .actionLink{
    margin-left:10px !important;
  }
}
@media only screen and (max-width: 1023px) {
  .player .sound,.wave-width{
    display: none;
  }
  .player .timer{
    margin-left:10px !important;
    margin-right:10px !important;
  }
  .player{
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .player .songInfo,.player .songInfo .actionLink{display: none !important;}
  .player {
    padding-left: 0;
    padding-right: 0;
    justify-content: center;
}
.player .timer{
  display: none;
}
.player .playControl{
  margin-right: 0px !important;
}
}

body.dark-theme{
  .player{
    background-color:#161616;
    border-top-color:#3e3e3e;
  }
  .player .playControl span{
    background-color: #262626;
  }
  .player .songInfo{
    border-right-color:#3e3e3e;
  }
  .player .sound:hover {
    background-color: #333333;
  }
  .volSlider{
    background-color:#000000;
  }
  .player .songInfo .infoText h5{
    color: #ffffff;
  }
  .player .songInfo .infoText .creator{
    color: #c3c3c3;
  }

  .playListBox{
    background-color: #323232;
  }

  .playItem .name {
    color:#ffffff;
  }

}