@import "../../variable";

.jobList{
    border-bottom: 1px solid #d9d9d9;
    position: relative;
    .info{
        font-size:14px;
        color:#999999;
    }
    .label{
        font-size:12px;
    }
    .info h6{        
        font-size:14px;
        color:#333333;
    }
    &:hover{
        h3{
            color:$primary;
        }
        border-bottom-color:#333333;
        &::before{
            content:"";
            position:absolute;
            width:40px;
            right:-40px;
            height:1px;
            background-color:#333333;
            bottom:-1px;
        }
        }

}

.applyBtn{
    color:#333333;
    i{
        font-size:70%;
    }
    &:hover{
        color:$primary;
        text-decoration: none;
    }
}