.main-page{
    min-height:100vh;
}


body.dark-theme{
    color:#ffffff;
    .main-page{
        background-color:#000000;
    }
}