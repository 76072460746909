@import "../../variable";

.gm-close{
    top: -16px;
    right: -16px;
    background: #000;
    color: #fff;
    padding: 5px;
    border-radius: 50%;
    position: absolute;
}
