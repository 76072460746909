/*------------------------
	icons
-------------------------*/


.lnir-500px::before {
  content: $lnir-500px;
}

.lnir-adobe::before {
  content: $lnir-adobe;
}

.lnir-airbnb::before {
  content: $lnir-airbnb;
}

.lnir-amazon-original::before {
  content: $lnir-amazon-original;
}

.lnir-amazon-pay::before {
  content: $lnir-amazon-pay;
}

.lnir-amazon::before {
  content: $lnir-amazon;
}

.lnir-angellist::before {
  content: $lnir-angellist;
}

.lnir-android-original::before {
  content: $lnir-android-original;
}

.lnir-android::before {
  content: $lnir-android;
}

.lnir-amex::before {
  content: $lnir-amex;
}

.lnir-angular::before {
  content: $lnir-angular;
}

.lnir-atlassian::before {
  content: $lnir-atlassian;
}

.lnir-apple-pay::before {
  content: $lnir-apple-pay;
}

.lnir-apple::before {
  content: $lnir-apple;
}

.lnir-app-store::before {
  content: $lnir-app-store;
}

.lnir-aws::before {
  content: $lnir-aws;
}

.lnir-behance-original::before {
  content: $lnir-behance-original;
}

.lnir-behance::before {
  content: $lnir-behance;
}

.lnir-bitbucket::before {
  content: $lnir-bitbucket;
}

.lnir-chrome::before {
  content: $lnir-chrome;
}

.lnir-bitcoin::before {
  content: $lnir-bitcoin;
}

.lnir-codepen::before {
  content: $lnir-codepen;
}

.lnir-bootstrap::before {
  content: $lnir-bootstrap;
}

.lnir-css3::before {
  content: $lnir-css3;
}

.lnir-blogger::before {
  content: $lnir-blogger;
}

.lnir-dev::before {
  content: $lnir-dev;
}

.lnir-diners-club::before {
  content: $lnir-diners-club;
}

.lnir-discord::before {
  content: $lnir-discord;
}

.lnir-discover::before {
  content: $lnir-discover;
}

.lnir-docker::before {
  content: $lnir-docker;
}

.lnir-dribbble::before {
  content: $lnir-dribbble;
}

.lnir-dropbox::before {
  content: $lnir-dropbox;
}

.lnir-drupal-original::before {
  content: $lnir-drupal-original;
}

.lnir-drupal::before {
  content: $lnir-drupal;
}

.lnir-edge::before {
  content: $lnir-edge;
}

.lnir-envato::before {
  content: $lnir-envato;
}

.lnir-facebook-filled::before {
  content: $lnir-facebook-filled;
}

.lnir-cpanel::before {
  content: $lnir-cpanel;
}

.lnir-creative-commons::before {
  content: $lnir-creative-commons;
}

.lnir-facebook-messenger::before {
  content: $lnir-facebook-messenger;
}

.lnir-facebook-original::before {
  content: $lnir-facebook-original;
}

.lnir-facebook-oval::before {
  content: $lnir-facebook-oval;
}

.lnir-facebook::before {
  content: $lnir-facebook;
}

.lnir-figma::before {
  content: $lnir-figma;
}

.lnir-firefox-original::before {
  content: $lnir-firefox-original;
}

.lnir-firefox::before {
  content: $lnir-firefox;
}

.lnir-flickr::before {
  content: $lnir-flickr;
}

.lnir-git::before {
  content: $lnir-git;
}

.lnir-github-original::before {
  content: $lnir-github-original;
}

.lnir-github::before {
  content: $lnir-github;
}

.lnir-goodreads::before {
  content: $lnir-goodreads;
}

.lnir-google-drive::before {
  content: $lnir-google-drive;
}

.lnir-google-pay::before {
  content: $lnir-google-pay;
}

.lnir-google-wallet::before {
  content: $lnir-google-wallet;
}

.lnir-google::before {
  content: $lnir-google;
}

.lnir-hacker-news::before {
  content: $lnir-hacker-news;
}

.lnir-html5::before {
  content: $lnir-html5;
}

.lnir-instagram-filled::before {
  content: $lnir-instagram-filled;
}

.lnir-instagram-original::before {
  content: $lnir-instagram-original;
}

.lnir-instagram::before {
  content: $lnir-instagram;
}

.lnir-javascript::before {
  content: $lnir-javascript;
}

.lnir-jcb::before {
  content: $lnir-jcb;
}

.lnir-joomla::before {
  content: $lnir-joomla;
}

.lnir-laravel::before {
  content: $lnir-laravel;
}

.lnir-lineicons-alt::before {
  content: $lnir-lineicons-alt;
}

.lnir-dropbox-original::before {
  content: $lnir-dropbox-original;
}

.lnir-linkedin-original::before {
  content: $lnir-linkedin-original;
}

.lnir-mailchimp::before {
  content: $lnir-mailchimp;
}

.lnir-mastercard::before {
  content: $lnir-mastercard;
}

.lnir-medium::before {
  content: $lnir-medium;
}

.lnir-megento::before {
  content: $lnir-megento;
}

.lnir-microsoft::before {
  content: $lnir-microsoft;
}

.lnir-nodejs-alt::before {
  content: $lnir-nodejs-alt;
}

.lnir-nodejs::before {
  content: $lnir-nodejs;
}

.lnir-npm::before {
  content: $lnir-npm;
}

.lnir-opera::before {
  content: $lnir-opera;
}

.lnir-patreon::before {
  content: $lnir-patreon;
}

.lnir-paypal-original::before {
  content: $lnir-paypal-original;
}

.lnir-paypal::before {
  content: $lnir-paypal;
}

.lnir-php::before {
  content: $lnir-php;
}

.lnir-line::before {
  content: $lnir-line;
}

.lnir-lineicons::before {
  content: $lnir-lineicons;
}

.lnir-linkedin::before {
  content: $lnir-linkedin;
}

.lnir-pinterest::before {
  content: $lnir-pinterest;
}

.lnir-play-store::before {
  content: $lnir-play-store;
}

.lnir-producthunt::before {
  content: $lnir-producthunt;
}

.lnir-python::before {
  content: $lnir-python;
}

.lnir-quora::before {
  content: $lnir-quora;
}

.lnir-react::before {
  content: $lnir-react;
}

.lnir-reddit::before {
  content: $lnir-reddit;
}

.lnir-shopify::before {
  content: $lnir-shopify;
}

.lnir-sketch::before {
  content: $lnir-sketch;
}

.lnir-skype::before {
  content: $lnir-skype;
}

.lnir-slack::before {
  content: $lnir-slack;
}

.lnir-slideshare::before {
  content: $lnir-slideshare;
}

.lnir-snapchat::before {
  content: $lnir-snapchat;
}

.lnir-souncloud-original::before {
  content: $lnir-souncloud-original;
}

.lnir-soundcloud::before {
  content: $lnir-soundcloud;
}

.lnir-spotify-original::before {
  content: $lnir-spotify-original;
}

.lnir-spotify::before {
  content: $lnir-spotify;
}

.lnir-stackoverflow::before {
  content: $lnir-stackoverflow;
}

.lnir-swift::before {
  content: $lnir-swift;
}

.lnir-telegram-original::before {
  content: $lnir-telegram-original;
}

.lnir-telegram::before {
  content: $lnir-telegram;
}

.lnir-trello::before {
  content: $lnir-trello;
}

.lnir-stripe::before {
  content: $lnir-stripe;
}

.lnir-tumblr::before {
  content: $lnir-tumblr;
}

.lnir-stumbleupon::before {
  content: $lnir-stumbleupon;
}

.lnir-twitter-original::before {
  content: $lnir-twitter-original;
}

.lnir-twitter::before {
  content: $lnir-twitter;
}

.lnir-ubuntu::before {
  content: $lnir-ubuntu;
}

.lnir-vimeo::before {
  content: $lnir-vimeo;
}

.lnir-twitch::before {
  content: $lnir-twitch;
}

.lnir-steam::before {
  content: $lnir-steam;
}

.lnir-twitter-filled::before {
  content: $lnir-twitter-filled;
}

.lnir-visa::before {
  content: $lnir-visa;
}

.lnir-vk::before {
  content: $lnir-vk;
}

.lnir-wechat::before {
  content: $lnir-wechat;
}

.lnir-whatsapp::before {
  content: $lnir-whatsapp;
}

.lnir-windows::before {
  content: $lnir-windows;
}

.lnir-wordpress-filled::before {
  content: $lnir-wordpress-filled;
}

.lnir-wordpress::before {
  content: $lnir-wordpress;
}

.lnir-yahoo::before {
  content: $lnir-yahoo;
}

.lnir-ycombinator::before {
  content: $lnir-ycombinator;
}

.lnir-youtube::before {
  content: $lnir-youtube;
}

.lnir-anchor::before {
  content: $lnir-anchor;
}

.lnir-bi-cycle::before {
  content: $lnir-bi-cycle;
}

.lnir-boarding-pass-alt::before {
  content: $lnir-boarding-pass-alt;
}

.lnir-boarding-pass::before {
  content: $lnir-boarding-pass;
}

.lnir-java::before {
  content: $lnir-java;
}

.lnir-jsfiddle::before {
  content: $lnir-jsfiddle;
}

.lnir-bridge::before {
  content: $lnir-bridge;
}

.lnir-bullhorn::before {
  content: $lnir-bullhorn;
}

.lnir-bus::before {
  content: $lnir-bus;
}

.lnir-car-alt::before {
  content: $lnir-car-alt;
}

.lnir-car::before {
  content: $lnir-car;
}

.lnir-caravan::before {
  content: $lnir-caravan;
}

.lnir-chat-add::before {
  content: $lnir-chat-add;
}

.lnir-chat-minus::before {
  content: $lnir-chat-minus;
}

.lnir-comments-alt::before {
  content: $lnir-comments-alt;
}

.lnir-comments-reply::before {
  content: $lnir-comments-reply;
}

.lnir-comments::before {
  content: $lnir-comments;
}

.lnir-envelope-alt::before {
  content: $lnir-envelope-alt;
}

.lnir-helicopter::before {
  content: $lnir-helicopter;
}

.lnir-inbox::before {
  content: $lnir-inbox;
}

.lnir-megaphone::before {
  content: $lnir-megaphone;
}

.lnir-message-attachement::before {
  content: $lnir-message-attachement;
}

.lnir-message-block::before {
  content: $lnir-message-block;
}

.lnir-message-check::before {
  content: $lnir-message-check;
}

.lnir-message-cross::before {
  content: $lnir-message-cross;
}

.lnir-message-desktop::before {
  content: $lnir-message-desktop;
}

.lnir-message-edit::before {
  content: $lnir-message-edit;
}

.lnir-message-forward::before {
  content: $lnir-message-forward;
}

.lnir-message-incoming::before {
  content: $lnir-message-incoming;
}

.lnir-message-laptop::before {
  content: $lnir-message-laptop;
}

.lnir-message-lock::before {
  content: $lnir-message-lock;
}

.lnir-message-love::before {
  content: $lnir-message-love;
}

.lnir-message-minus::before {
  content: $lnir-message-minus;
}

.lnir-message-outgoing::before {
  content: $lnir-message-outgoing;
}

.lnir-message-plus::before {
  content: $lnir-message-plus;
}

.lnir-message-reply::before {
  content: $lnir-message-reply;
}

.lnir-message-search::before {
  content: $lnir-message-search;
}

.lnir-message-share::before {
  content: $lnir-message-share;
}

.lnir-mobile-chat::before {
  content: $lnir-mobile-chat;
}

.lnir-page::before {
  content: $lnir-page;
}

.lnir-phone-block::before {
  content: $lnir-phone-block;
}

.lnir-phone-caling::before {
  content: $lnir-phone-caling;
}

.lnir-phone-conference::before {
  content: $lnir-phone-conference;
}

.lnir-phone-cut::before {
  content: $lnir-phone-cut;
}

.lnir-phone-forward::before {
  content: $lnir-phone-forward;
}

.lnir-phone-incoming::before {
  content: $lnir-phone-incoming;
}

.lnir-phone-misscall::before {
  content: $lnir-phone-misscall;
}

.lnir-phone-outgoing::before {
  content: $lnir-phone-outgoing;
}

.lnir-phone-pause::before {
  content: $lnir-phone-pause;
}

.lnir-phone-redial::before {
  content: $lnir-phone-redial;
}

.lnir-phone-ring::before {
  content: $lnir-phone-ring;
}

.lnir-phone-set::before {
  content: $lnir-phone-set;
}

.lnir-phone::before {
  content: $lnir-phone;
}

.lnir-plane-alt::before {
  content: $lnir-plane-alt;
}

.lnir-plane::before {
  content: $lnir-plane;
}

.lnir-popup::before {
  content: $lnir-popup;
}

.lnir-postcard-alt::before {
  content: $lnir-postcard-alt;
}

.lnir-postcard::before {
  content: $lnir-postcard;
}

.lnir-reply::before {
  content: $lnir-reply;
}

.lnir-road::before {
  content: $lnir-road;
}

.lnir-scooter::before {
  content: $lnir-scooter;
}

.lnir-ship::before {
  content: $lnir-ship;
}

.lnir-support::before {
  content: $lnir-support;
}

.lnir-target-mail::before {
  content: $lnir-target-mail;
}

.lnir-taxi::before {
  content: $lnir-taxi;
}

.lnir-train-alt::before {
  content: $lnir-train-alt;
}

.lnir-train::before {
  content: $lnir-train;
}

.lnir-boundary::before {
  content: $lnir-boundary;
}

.lnir-bricks-alt::before {
  content: $lnir-bricks-alt;
}

.lnir-bricks::before {
  content: $lnir-bricks;
}

.lnir-bulldozer::before {
  content: $lnir-bulldozer;
}

.lnir-cone::before {
  content: $lnir-cone;
}

.lnir-construction-hammer::before {
  content: $lnir-construction-hammer;
}

.lnir-construction-rular::before {
  content: $lnir-construction-rular;
}

.lnir-construction::before {
  content: $lnir-construction;
}

.lnir-door-alt::before {
  content: $lnir-door-alt;
}

.lnir-drill::before {
  content: $lnir-drill;
}

.lnir-fence::before {
  content: $lnir-fence;
}

.lnir-hammer-alt::before {
  content: $lnir-hammer-alt;
}

.lnir-envelope::before {
  content: $lnir-envelope;
}

.lnir-joomla-original::before {
  content: $lnir-joomla-original;
}

.lnir-bubble::before {
  content: $lnir-bubble;
}

.lnir-door::before {
  content: $lnir-door;
}

.lnir-helmet::before {
  content: $lnir-helmet;
}

.lnir-hook::before {
  content: $lnir-hook;
}

.lnir-house-alt-1::before {
  content: $lnir-house-alt-1;
}

.lnir-house-alt::before {
  content: $lnir-house-alt;
}

.lnir-house-heart::before {
  content: $lnir-house-heart;
}

.lnir-house-plan::before {
  content: $lnir-house-plan;
}

.lnir-house::before {
  content: $lnir-house;
}

.lnir-measuring-tape::before {
  content: $lnir-measuring-tape;
}

.lnir-paint-bucket::before {
  content: $lnir-paint-bucket;
}

.lnir-paint-roller-alt-1::before {
  content: $lnir-paint-roller-alt-1;
}

.lnir-paint-roller-alt::before {
  content: $lnir-paint-roller-alt;
}

.lnir-paint-roller::before {
  content: $lnir-paint-roller;
}

.lnir-pipe::before {
  content: $lnir-pipe;
}

.lnir-ruler-alt::before {
  content: $lnir-ruler-alt;
}

.lnir-ruler-pencil::before {
  content: $lnir-ruler-pencil;
}

.lnir-ruler::before {
  content: $lnir-ruler;
}

.lnir-saw-alt::before {
  content: $lnir-saw-alt;
}

.lnir-saw::before {
  content: $lnir-saw;
}

.lnir-screw::before {
  content: $lnir-screw;
}

.lnir-screwdriver::before {
  content: $lnir-screwdriver;
}

.lnir-shovel::before {
  content: $lnir-shovel;
}

.lnir-toolbox::before {
  content: $lnir-toolbox;
}

.lnir-triangle-ruler-1::before {
  content: $lnir-triangle-ruler-1;
}

.lnir-triangle-ruler::before {
  content: $lnir-triangle-ruler;
}

.lnir-trowel-alt::before {
  content: $lnir-trowel-alt;
}

.lnir-trowel::before {
  content: $lnir-trowel;
}

.lnir-wheelbarrow::before {
  content: $lnir-wheelbarrow;
}

.lnir-wrench::before {
  content: $lnir-wrench;
}

.lnir-brush-alt::before {
  content: $lnir-brush-alt;
}

.lnir-brush::before {
  content: $lnir-brush;
}

.lnir-color-palette::before {
  content: $lnir-color-palette;
}

.lnir-crop-alt::before {
  content: $lnir-crop-alt;
}

.lnir-crop::before {
  content: $lnir-crop;
}

.lnir-grid-alt::before {
  content: $lnir-grid-alt;
}

.lnir-grid::before {
  content: $lnir-grid;
}

.lnir-highlight-alt::before {
  content: $lnir-highlight-alt;
}

.lnir-highlight::before {
  content: $lnir-highlight;
}

.lnir-image-crop::before {
  content: $lnir-image-crop;
}

.lnir-layers-alt-1::before {
  content: $lnir-layers-alt-1;
}

.lnir-layers-alt::before {
  content: $lnir-layers-alt;
}

.lnir-layers::before {
  content: $lnir-layers;
}

.lnir-layout-alt-1::before {
  content: $lnir-layout-alt-1;
}

.lnir-layout-alt-2::before {
  content: $lnir-layout-alt-2;
}

.lnir-layout-alt::before {
  content: $lnir-layout-alt;
}

.lnir-layout::before {
  content: $lnir-layout;
}

.lnir-magic-wand::before {
  content: $lnir-magic-wand;
}

.lnir-margin::before {
  content: $lnir-margin;
}

.lnir-move::before {
  content: $lnir-move;
}

.lnir-pallet::before {
  content: $lnir-pallet;
}

.lnir-rotate::before {
  content: $lnir-rotate;
}

.lnir-scaling::before {
  content: $lnir-scaling;
}

.lnir-select::before {
  content: $lnir-select;
}

.lnir-size::before {
  content: $lnir-size;
}

.lnir-slice::before {
  content: $lnir-slice;
}

.lnir-transform::before {
  content: $lnir-transform;
}

.lnir-ux::before {
  content: $lnir-ux;
}

.lnir-vector-alt::before {
  content: $lnir-vector-alt;
}

.lnir-vector-pen::before {
  content: $lnir-vector-pen;
}

.lnir-vector::before {
  content: $lnir-vector;
}

.lnir-angle-double-down::before {
  content: $lnir-angle-double-down;
}

.lnir-angle-double-left::before {
  content: $lnir-angle-double-left;
}

.lnir-angle-double-right::before {
  content: $lnir-angle-double-right;
}

.lnir-angle-double-up::before {
  content: $lnir-angle-double-up;
}

.lnir-arrow-double-down::before {
  content: $lnir-arrow-double-down;
}

.lnir-arrow-double-left::before {
  content: $lnir-arrow-double-left;
}

.lnir-arrow-double-right::before {
  content: $lnir-arrow-double-right;
}

.lnir-arrow-double-top::before {
  content: $lnir-arrow-double-top;
}

.lnir-arrow-down-circle::before {
  content: $lnir-arrow-down-circle;
}

.lnir-arrow-down::before {
  content: $lnir-arrow-down;
}

.lnir-arrow-expand-alt::before {
  content: $lnir-arrow-expand-alt;
}

.lnir-arrow-expand::before {
  content: $lnir-arrow-expand;
}

.lnir-arrow-left-circle::before {
  content: $lnir-arrow-left-circle;
}

.lnir-arrow-left::before {
  content: $lnir-arrow-left;
}

.lnir-arrow-minimize-alt::before {
  content: $lnir-arrow-minimize-alt;
}

.lnir-arrow-minimize::before {
  content: $lnir-arrow-minimize;
}

.lnir-arrow-right-circle::before {
  content: $lnir-arrow-right-circle;
}

.lnir-arrow-right::before {
  content: $lnir-arrow-right;
}

.lnir-arrow-top-left::before {
  content: $lnir-arrow-top-left;
}

.lnir-arrow-top-right::before {
  content: $lnir-arrow-top-right;
}

.lnir-arrow-up-circle::before {
  content: $lnir-arrow-up-circle;
}

.lnir-arrow-up::before {
  content: $lnir-arrow-up;
}

.lnir-arrows-horizontal::before {
  content: $lnir-arrows-horizontal;
}

.lnir-arrows-vertical::before {
  content: $lnir-arrows-vertical;
}

.lnir-axis::before {
  content: $lnir-axis;
}

.lnir-chevron-down-circle::before {
  content: $lnir-chevron-down-circle;
}

.lnir-chevron-down::before {
  content: $lnir-chevron-down;
}

.lnir-chevron-left-circle::before {
  content: $lnir-chevron-left-circle;
}

.lnir-chevron-left::before {
  content: $lnir-chevron-left;
}

.lnir-chevron-right-circle::before {
  content: $lnir-chevron-right-circle;
}

.lnir-chevron-right::before {
  content: $lnir-chevron-right;
}

.lnir-chevron-up-circle::before {
  content: $lnir-chevron-up-circle;
}

.lnir-chevron-up::before {
  content: $lnir-chevron-up;
}

.lnir-direction-alt::before {
  content: $lnir-direction-alt;
}

.lnir-direction-ltr::before {
  content: $lnir-direction-ltr;
}

.lnir-direction-rtl::before {
  content: $lnir-direction-rtl;
}

.lnir-direction::before {
  content: $lnir-direction;
}

.lnir-down-arrow-box::before {
  content: $lnir-down-arrow-box;
}

.lnir-down-left-arrow-box::before {
  content: $lnir-down-left-arrow-box;
}

.lnir-down-right-arrow-box::before {
  content: $lnir-down-right-arrow-box;
}

.lnir-enter::before {
  content: $lnir-enter;
}

.lnir-exit-down::before {
  content: $lnir-exit-down;
}

.lnir-exit-up::before {
  content: $lnir-exit-up;
}

.lnir-exit::before {
  content: $lnir-exit;
}

.lnir-left-arrow-box::before {
  content: $lnir-left-arrow-box;
}

.lnir-pointer-down::before {
  content: $lnir-pointer-down;
}

.lnir-pointer-left::before {
  content: $lnir-pointer-left;
}

.lnir-pointer-right::before {
  content: $lnir-pointer-right;
}

.lnir-pointer-up::before {
  content: $lnir-pointer-up;
}

.lnir-right-arrow-box::before {
  content: $lnir-right-arrow-box;
}

.lnir-shift-left::before {
  content: $lnir-shift-left;
}

.lnir-shift-right::before {
  content: $lnir-shift-right;
}

.lnir-top-left-arrow-box::before {
  content: $lnir-top-left-arrow-box;
}

.lnir-top-arrow-box::before {
  content: $lnir-top-arrow-box;
}

.lnir-top-right-arrow-box::before {
  content: $lnir-top-right-arrow-box;
}

.lnir-capital-letter::before {
  content: $lnir-capital-letter;
}

.lnir-font::before {
  content: $lnir-font;
}

.lnir-height::before {
  content: $lnir-height;
}

.lnir-indent-decrease::before {
  content: $lnir-indent-decrease;
}

.lnir-indent-increase::before {
  content: $lnir-indent-increase;
}

.lnir-italic::before {
  content: $lnir-italic;
}

.lnir-line-dashed::before {
  content: $lnir-line-dashed;
}

.lnir-line-dotted::before {
  content: $lnir-line-dotted;
}

.lnir-line-double::before {
  content: $lnir-line-double;
}

.lnir-line-spacing::before {
  content: $lnir-line-spacing;
}

.lnir-page-break::before {
  content: $lnir-page-break;
}

.lnir-pagination::before {
  content: $lnir-pagination;
}

.lnir-pilcrow::before {
  content: $lnir-pilcrow;
}

.lnir-redo::before {
  content: $lnir-redo;
}

.lnir-reflect::before {
  content: $lnir-reflect;
}

.lnir-sort-alpha-asc::before {
  content: $lnir-sort-alpha-asc;
}

.lnir-sort-amount-asc::before {
  content: $lnir-sort-amount-asc;
}

.lnir-sort-amount-dsc::before {
  content: $lnir-sort-amount-dsc;
}

.lnir-spellcheck::before {
  content: $lnir-spellcheck;
}

.lnir-text-align-justify::before {
  content: $lnir-text-align-justify;
}

.lnir-text-align-left::before {
  content: $lnir-text-align-left;
}

.lnir-hammer::before {
  content: $lnir-hammer;
}

.lnir-helmet-alt::before {
  content: $lnir-helmet-alt;
}

.lnir-text-align-right::before {
  content: $lnir-text-align-right;
}

.lnir-text-align-center::before {
  content: $lnir-text-align-center;
}

.lnir-text-format-remove::before {
  content: $lnir-text-format-remove;
}

.lnir-text::before {
  content: $lnir-text;
}

.lnir-underline::before {
  content: $lnir-underline;
}

.lnir-undo::before {
  content: $lnir-undo;
}

.lnir-vertical-line::before {
  content: $lnir-vertical-line;
}

.lnir-atom::before {
  content: $lnir-atom;
}

.lnir-backpack-alt::before {
  content: $lnir-backpack-alt;
}

.lnir-backpack::before {
  content: $lnir-backpack;
}

.lnir-blackboard::before {
  content: $lnir-blackboard;
}

.lnir-book-alt::before {
  content: $lnir-book-alt;
}

.lnir-book::before {
  content: $lnir-book;
}

.lnir-books-alt::before {
  content: $lnir-books-alt;
}

.lnir-books::before {
  content: $lnir-books;
}

.lnir-brakckets::before {
  content: $lnir-brakckets;
}

.lnir-certificate-alt-1::before {
  content: $lnir-certificate-alt-1;
}

.lnir-certificate-alt::before {
  content: $lnir-certificate-alt;
}

.lnir-certificate::before {
  content: $lnir-certificate;
}

.lnir-classroom::before {
  content: $lnir-classroom;
}

.lnir-clipboard::before {
  content: $lnir-clipboard;
}

.lnir-compass::before {
  content: $lnir-compass;
}

.lnir-cubes::before {
  content: $lnir-cubes;
}

.lnir-eraser::before {
  content: $lnir-eraser;
}

.lnir-flask-alt-1::before {
  content: $lnir-flask-alt-1;
}

.lnir-flask-alt-2::before {
  content: $lnir-flask-alt-2;
}

.lnir-flask-alt::before {
  content: $lnir-flask-alt;
}

.lnir-flask::before {
  content: $lnir-flask;
}

.lnir-graduate-alt::before {
  content: $lnir-graduate-alt;
}

.lnir-graduate::before {
  content: $lnir-graduate;
}

.lnir-graduation::before {
  content: $lnir-graduation;
}

.lnir-letterblocks::before {
  content: $lnir-letterblocks;
}

.lnir-library::before {
  content: $lnir-library;
}

.lnir-medal-alt::before {
  content: $lnir-medal-alt;
}

.lnir-medal::before {
  content: $lnir-medal;
}

.lnir-molecule-alt::before {
  content: $lnir-molecule-alt;
}

.lnir-molecule::before {
  content: $lnir-molecule;
}

.lnir-pencil-alt::before {
  content: $lnir-pencil-alt;
}

.lnir-pencil::before {
  content: $lnir-pencil;
}

.lnir-quill::before {
  content: $lnir-quill;
}

.lnir-school-bench-alt::before {
  content: $lnir-school-bench-alt;
}

.lnir-school-bench::before {
  content: $lnir-school-bench;
}

.lnir-sketchbook::before {
  content: $lnir-sketchbook;
}

.lnir-whiteboard-alt-1::before {
  content: $lnir-whiteboard-alt-1;
}

.lnir-whiteboard-alt-2::before {
  content: $lnir-whiteboard-alt-2;
}

.lnir-whiteboard-alt::before {
  content: $lnir-whiteboard-alt;
}

.lnir-whiteboard::before {
  content: $lnir-whiteboard;
}

.lnir-world-2::before {
  content: $lnir-world-2;
}

.lnir-world-alt::before {
  content: $lnir-world-alt;
}

.lnir-world::before {
  content: $lnir-world;
}

.lnir-write::before {
  content: $lnir-write;
}

.lnir-apple-alt::before {
  content: $lnir-apple-alt;
}

.lnir-banana::before {
  content: $lnir-banana;
}

.lnir-bowl-alt::before {
  content: $lnir-bowl-alt;
}

.lnir-bowl-alt-1::before {
  content: $lnir-bowl-alt-1;
}

.lnir-bowl::before {
  content: $lnir-bowl;
}

.lnir-brocolli::before {
  content: $lnir-brocolli;
}

.lnir-burger-alt-1::before {
  content: $lnir-burger-alt-1;
}

.lnir-burger-alt::before {
  content: $lnir-burger-alt;
}

.lnir-burger::before {
  content: $lnir-burger;
}

.lnir-chef-hat-alt-1::before {
  content: $lnir-chef-hat-alt-1;
}

.lnir-chef-hat-alt-2::before {
  content: $lnir-chef-hat-alt-2;
}

.lnir-chef-hat-alt::before {
  content: $lnir-chef-hat-alt;
}

.lnir-chef-hat::before {
  content: $lnir-chef-hat;
}

.lnir-chicken::before {
  content: $lnir-chicken;
}

.lnir-cocktail-alt-1::before {
  content: $lnir-cocktail-alt-1;
}

.lnir-cocktail-alt::before {
  content: $lnir-cocktail-alt;
}

.lnir-cocktail::before {
  content: $lnir-cocktail;
}

.lnir-coffee-cup-alt::before {
  content: $lnir-coffee-cup-alt;
}

.lnir-coffee-cup::before {
  content: $lnir-coffee-cup;
}

.lnir-cupcake-alt::before {
  content: $lnir-cupcake-alt;
}

.lnir-cupcake::before {
  content: $lnir-cupcake;
}

.lnir-cutlery-alt-1::before {
  content: $lnir-cutlery-alt-1;
}

.lnir-cutlery-alt::before {
  content: $lnir-cutlery-alt;
}

.lnir-cutlery::before {
  content: $lnir-cutlery;
}

.lnir-dinner-table::before {
  content: $lnir-dinner-table;
}

.lnir-dinner::before {
  content: $lnir-dinner;
}

.lnir-drumstick::before {
  content: $lnir-drumstick;
}

.lnir-fresh-juice::before {
  content: $lnir-fresh-juice;
}

.lnir-ice-cream-alt::before {
  content: $lnir-ice-cream-alt;
}

.lnir-ice-cream::before {
  content: $lnir-ice-cream;
}

.lnir-juice::before {
  content: $lnir-juice;
}

.lnir-lolypop::before {
  content: $lnir-lolypop;
}

.lnir-meat::before {
  content: $lnir-meat;
}

.lnir-pizza-alt::before {
  content: $lnir-pizza-alt;
}

.lnir-pizza::before {
  content: $lnir-pizza;
}

.lnir-plate::before {
  content: $lnir-plate;
}

.lnir-popcorn::before {
  content: $lnir-popcorn;
}

.lnir-restaurant::before {
  content: $lnir-restaurant;
}

.lnir-service::before {
  content: $lnir-service;
}

.lnir-silverware::before {
  content: $lnir-silverware;
}

.lnir-spatula::before {
  content: $lnir-spatula;
}

.lnir-ambulance-alt-1::before {
  content: $lnir-ambulance-alt-1;
}

.lnir-ambulance-alt::before {
  content: $lnir-ambulance-alt;
}

.lnir-ambulance::before {
  content: $lnir-ambulance;
}

.lnir-basketball::before {
  content: $lnir-basketball;
}

.lnir-bone::before {
  content: $lnir-bone;
}

.lnir-brain-alt::before {
  content: $lnir-brain-alt;
}

.lnir-brain-check-alt::before {
  content: $lnir-brain-check-alt;
}

.lnir-brain-check::before {
  content: $lnir-brain-check;
}

.lnir-brain::before {
  content: $lnir-brain;
}

.lnir-broken-bone::before {
  content: $lnir-broken-bone;
}

.lnir-capsule-alt-1::before {
  content: $lnir-capsule-alt-1;
}

.lnir-capsule-alt::before {
  content: $lnir-capsule-alt;
}

.lnir-capsule::before {
  content: $lnir-capsule;
}

.lnir-capsules::before {
  content: $lnir-capsules;
}

.lnir-cardiology::before {
  content: $lnir-cardiology;
}

.lnir-diagnosis-alt::before {
  content: $lnir-diagnosis-alt;
}

.lnir-diagnosis::before {
  content: $lnir-diagnosis;
}

.lnir-dna::before {
  content: $lnir-dna;
}

.lnir-dropper-alt-1::before {
  content: $lnir-dropper-alt-1;
}

.lnir-dropper-alt-2::before {
  content: $lnir-dropper-alt-2;
}

.lnir-dropper-alt::before {
  content: $lnir-dropper-alt;
}

.lnir-dropper::before {
  content: $lnir-dropper;
}

.lnir-dumbbell::before {
  content: $lnir-dumbbell;
}

.lnir-eye-test::before {
  content: $lnir-eye-test;
}

.lnir-female::before {
  content: $lnir-female;
}

.lnir-first-aid::before {
  content: $lnir-first-aid;
}

.lnir-gluten-free::before {
  content: $lnir-gluten-free;
}

.lnir-heart-check::before {
  content: $lnir-heart-check;
}

.lnir-heart-monitor::before {
  content: $lnir-heart-monitor;
}

.lnir-heartrate-monitor::before {
  content: $lnir-heartrate-monitor;
}

.lnir-hospital-alt-1::before {
  content: $lnir-hospital-alt-1;
}

.lnir-hospital-alt-2::before {
  content: $lnir-hospital-alt-2;
}

.lnir-hospital-alt-3::before {
  content: $lnir-hospital-alt-3;
}

.lnir-hospital-alt-4::before {
  content: $lnir-hospital-alt-4;
}

.lnir-hospital-alt::before {
  content: $lnir-hospital-alt;
}

.lnir-hospital-bed-alt-1::before {
  content: $lnir-hospital-bed-alt-1;
}

.lnir-hospital-bed-alt-2::before {
  content: $lnir-hospital-bed-alt-2;
}

.lnir-hospital-bed-alt::before {
  content: $lnir-hospital-bed-alt;
}

.lnir-hospital-bed::before {
  content: $lnir-hospital-bed;
}

.lnir-hospital-location::before {
  content: $lnir-hospital-location;
}

.lnir-hospital-sign::before {
  content: $lnir-hospital-sign;
}

.lnir-hospital::before {
  content: $lnir-hospital;
}

.lnir-kettlebell::before {
  content: $lnir-kettlebell;
}

.lnir-lungs::before {
  content: $lnir-lungs;
}

.lnir-male::before {
  content: $lnir-male;
}

.lnir-medical-briefcase-alt::before {
  content: $lnir-medical-briefcase-alt;
}

.lnir-medical-briefcase::before {
  content: $lnir-medical-briefcase;
}

.lnir-medical-protection::before {
  content: $lnir-medical-protection;
}

.lnir-medical-sign::before {
  content: $lnir-medical-sign;
}

.lnir-medicine-alt::before {
  content: $lnir-medicine-alt;
}

.lnir-medicine-bottle::before {
  content: $lnir-medicine-bottle;
}

.lnir-medicine::before {
  content: $lnir-medicine;
}

.lnir-microscope::before {
  content: $lnir-microscope;
}

.lnir-pulse::before {
  content: $lnir-pulse;
}

.lnir-report-laptop::before {
  content: $lnir-report-laptop;
}

.lnir-saline-alt-1::before {
  content: $lnir-saline-alt-1;
}

.lnir-saline::before {
  content: $lnir-saline;
}

.lnir-skipping-rope::before {
  content: $lnir-skipping-rope;
}

.lnir-slim::before {
  content: $lnir-slim;
}

.lnir-sthethoscope::before {
  content: $lnir-sthethoscope;
}

.lnir-saline-alt::before {
  content: $lnir-saline-alt;
}

.lnir-syringe::before {
  content: $lnir-syringe;
}

.lnir-test-tube-alt::before {
  content: $lnir-test-tube-alt;
}

.lnir-test-tube::before {
  content: $lnir-test-tube;
}

.lnir-thermometer-alt::before {
  content: $lnir-thermometer-alt;
}

.lnir-thermometer::before {
  content: $lnir-thermometer;
}

.lnir-tooth::before {
  content: $lnir-tooth;
}

.lnir-walker::before {
  content: $lnir-walker;
}

.lnir-weight-machine::before {
  content: $lnir-weight-machine;
}

.lnir-weight::before {
  content: $lnir-weight;
}

.lnir-wheelchair::before {
  content: $lnir-wheelchair;
}

.lnir-yoga-mat::before {
  content: $lnir-yoga-mat;
}

.lnir-achievement::before {
  content: $lnir-achievement;
}

.lnir-analytics-alt-1::before {
  content: $lnir-analytics-alt-1;
}

.lnir-agenda::before {
  content: $lnir-agenda;
}

.lnir-apartment-alt::before {
  content: $lnir-apartment-alt;
}

.lnir-apartment::before {
  content: $lnir-apartment;
}

.lnir-briefcase-alt::before {
  content: $lnir-briefcase-alt;
}

.lnir-briefcase::before {
  content: $lnir-briefcase;
}

.lnir-cart-alt::before {
  content: $lnir-cart-alt;
}

.lnir-cart-down::before {
  content: $lnir-cart-down;
}

.lnir-cart-empty::before {
  content: $lnir-cart-empty;
}

.lnir-cart-full::before {
  content: $lnir-cart-full;
}

.lnir-cart::before {
  content: $lnir-cart;
}

.lnir-consulting::before {
  content: $lnir-consulting;
}

.lnir-customer::before {
  content: $lnir-customer;
}

.lnir-delivery::before {
  content: $lnir-delivery;
}

.lnir-desk::before {
  content: $lnir-desk;
}

.lnir-global::before {
  content: $lnir-global;
}

.lnir-grow::before {
  content: $lnir-grow;
}

.lnir-handshake::before {
  content: $lnir-handshake;
}

.lnir-hierchy-alt::before {
  content: $lnir-hierchy-alt;
}

.lnir-hierchy::before {
  content: $lnir-hierchy;
}

.lnir-invest-monitor::before {
  content: $lnir-invest-monitor;
}

.lnir-investment::before {
  content: $lnir-investment;
}

.lnir-license::before {
  content: $lnir-license;
}

.lnir-notepad::before {
  content: $lnir-notepad;
}

.lnir-offer::before {
  content: $lnir-offer;
}

.lnir-office-plan::before {
  content: $lnir-office-plan;
}

.lnir-pie-chart-alt::before {
  content: $lnir-pie-chart-alt;
}

.lnir-paperclip::before {
  content: $lnir-paperclip;
}

.lnir-pie-chart-dollar::before {
  content: $lnir-pie-chart-dollar;
}

.lnir-pie-chart::before {
  content: $lnir-pie-chart;
}

.lnir-pie-report::before {
  content: $lnir-pie-report;
}

.lnir-pin-alt::before {
  content: $lnir-pin-alt;
}

.lnir-pin::before {
  content: $lnir-pin;
}

.lnir-quotation::before {
  content: $lnir-quotation;
}

.lnir-revenue::before {
  content: $lnir-revenue;
}

.lnir-sales-report::before {
  content: $lnir-sales-report;
}

.lnir-stamp::before {
  content: $lnir-stamp;
}

.lnir-stats-down::before {
  content: $lnir-stats-down;
}

.lnir-stats-up::before {
  content: $lnir-stats-up;
}

.lnir-swatches::before {
  content: $lnir-swatches;
}

.lnir-table::before {
  content: $lnir-table;
}

.lnir-tablet-alt::before {
  content: $lnir-tablet-alt;
}

.lnir-tag::before {
  content: $lnir-tag;
}

.lnir-target-achievement::before {
  content: $lnir-target-achievement;
}

.lnir-target-alt-1::before {
  content: $lnir-target-alt-1;
}

.lnir-target-alt::before {
  content: $lnir-target-alt;
}

.lnir-target-remove::before {
  content: $lnir-target-remove;
}

.lnir-target-revenue::before {
  content: $lnir-target-revenue;
}

.lnir-target::before {
  content: $lnir-target;
}

.lnir-tie::before {
  content: $lnir-tie;
}

.lnir-analytics::before {
  content: $lnir-analytics;
}

.lnir-bar-chart::before {
  content: $lnir-bar-chart;
}

.lnir-bar-check::before {
  content: $lnir-bar-check;
}

.lnir-billboard-alt::before {
  content: $lnir-billboard-alt;
}

.lnir-billboard::before {
  content: $lnir-billboard;
}

.lnir-graph-alt-1::before {
  content: $lnir-graph-alt-1;
}

.lnir-graph-alt-2::before {
  content: $lnir-graph-alt-2;
}

.lnir-graph-alt-3::before {
  content: $lnir-graph-alt-3;
}

.lnir-graph-alt-4::before {
  content: $lnir-graph-alt-4;
}

.lnir-graph-alt::before {
  content: $lnir-graph-alt;
}

.lnir-graph-decrease::before {
  content: $lnir-graph-decrease;
}

.lnir-graph-increase::before {
  content: $lnir-graph-increase;
}

.lnir-graph::before {
  content: $lnir-graph;
}

.lnir-keyword-research::before {
  content: $lnir-keyword-research;
}

.lnir-network-alt::before {
  content: $lnir-network-alt;
}

.lnir-network::before {
  content: $lnir-network;
}

.lnir-pyramid-decrease::before {
  content: $lnir-pyramid-decrease;
}

.lnir-pyramid-increase::before {
  content: $lnir-pyramid-increase;
}

.lnir-pyramid::before {
  content: $lnir-pyramid;
}

.lnir-rook::before {
  content: $lnir-rook;
}

.lnir-seo::before {
  content: $lnir-seo;
}

.lnir-strategy::before {
  content: $lnir-strategy;
}

.lnir-target-audience-alt::before {
  content: $lnir-target-audience-alt;
}

.lnir-target-customer::before {
  content: $lnir-target-customer;
}

.lnir-website-rank::before {
  content: $lnir-website-rank;
}

.lnir-barrier::before {
  content: $lnir-barrier;
}

.lnir-bolt-alt::before {
  content: $lnir-bolt-alt;
}

.lnir-bolt::before {
  content: $lnir-bolt;
}

.lnir-checkbox::before {
  content: $lnir-checkbox;
}

.lnir-checkmark-circle::before {
  content: $lnir-checkmark-circle;
}

.lnir-checkmark::before {
  content: $lnir-checkmark;
}

.lnir-circle-minus::before {
  content: $lnir-circle-minus;
}

.lnir-circle-plus::before {
  content: $lnir-circle-plus;
}

.lnir-close::before {
  content: $lnir-close;
}

.lnir-cross-circle::before {
  content: $lnir-cross-circle;
}

.lnir-electricity::before {
  content: $lnir-electricity;
}

.lnir-eye-alt::before {
  content: $lnir-eye-alt;
}

.lnir-eye::before {
  content: $lnir-eye;
}

.lnir-find::before {
  content: $lnir-find;
}

.lnir-flag-alt::before {
  content: $lnir-flag-alt;
}

.lnir-flag::before {
  content: $lnir-flag;
}

.lnir-funnel-alt-1::before {
  content: $lnir-funnel-alt-1;
}

.lnir-funnel-alt::before {
  content: $lnir-funnel-alt;
}

.lnir-funnel::before {
  content: $lnir-funnel;
}

.lnir-hand::before {
  content: $lnir-hand;
}

.lnir-heart-filled::before {
  content: $lnir-heart-filled;
}

.lnir-heart-plus::before {
  content: $lnir-heart-plus;
}

.lnir-heart::before {
  content: $lnir-heart;
}

.lnir-help::before {
  content: $lnir-help;
}

.lnir-hotel-sign::before {
  content: $lnir-hotel-sign;
}

.lnir-idea-alt::before {
  content: $lnir-idea-alt;
}

.lnir-identity::before {
  content: $lnir-identity;
}

.lnir-infinite::before {
  content: $lnir-infinite;
}

.lnir-information::before {
  content: $lnir-information;
}

.lnir-invention::before {
  content: $lnir-invention;
}

.lnir-key-alt-1::before {
  content: $lnir-key-alt-1;
}

.lnir-key-alt-2::before {
  content: $lnir-key-alt-2;
}

.lnir-key-alt-3::before {
  content: $lnir-key-alt-3;
}

.lnir-key-alt::before {
  content: $lnir-key-alt;
}

.lnir-key::before {
  content: $lnir-key;
}

.lnir-life-ring::before {
  content: $lnir-life-ring;
}

.lnir-list-alt-1::before {
  content: $lnir-list-alt-1;
}

.lnir-list-alt::before {
  content: $lnir-list-alt;
}

.lnir-list::before {
  content: $lnir-list;
}

.lnir-lock-alt-1::before {
  content: $lnir-lock-alt-1;
}

.lnir-lock-alt-2::before {
  content: $lnir-lock-alt-2;
}

.lnir-lock-alt::before {
  content: $lnir-lock-alt;
}

.lnir-lock::before {
  content: $lnir-lock;
}

.lnir-minus::before {
  content: $lnir-minus;
}

.lnir-more-alt-1::before {
  content: $lnir-more-alt-1;
}

.lnir-more-alt::before {
  content: $lnir-more-alt;
}

.lnir-more::before {
  content: $lnir-more;
}

.lnir-music-note::before {
  content: $lnir-music-note;
}

.lnir-music::before {
  content: $lnir-music;
}

.lnir-notification::before {
  content: $lnir-notification;
}

.lnir-passport-alt::before {
  content: $lnir-passport-alt;
}

.lnir-passport::before {
  content: $lnir-passport;
}

.lnir-playlist-alt::before {
  content: $lnir-playlist-alt;
}

.lnir-playlist::before {
  content: $lnir-playlist;
}

.lnir-plus::before {
  content: $lnir-plus;
}

.lnir-pointer::before {
  content: $lnir-pointer;
}

.lnir-power-switch::before {
  content: $lnir-power-switch;
}

.lnir-protection::before {
  content: $lnir-protection;
}

.lnir-question-circle::before {
  content: $lnir-question-circle;
}

.lnir-radio-button::before {
  content: $lnir-radio-button;
}

.lnir-record::before {
  content: $lnir-record;
}

.lnir-round-box-check::before {
  content: $lnir-round-box-check;
}

.lnir-round-box-cross::before {
  content: $lnir-round-box-cross;
}

.lnir-round-box-minus::before {
  content: $lnir-round-box-minus;
}

.lnir-round-box-plus::before {
  content: $lnir-round-box-plus;
}

.lnir-shield::before {
  content: $lnir-shield;
}

.lnir-sort::before {
  content: $lnir-sort;
}

.lnir-switch::before {
  content: $lnir-switch;
}

.lnir-teabag::before {
  content: $lnir-teabag;
}

.lnir-thumbs-down-alt::before {
  content: $lnir-thumbs-down-alt;
}

.lnir-thumbs-down::before {
  content: $lnir-thumbs-down;
}

.lnir-thumbs-up-alt::before {
  content: $lnir-thumbs-up-alt;
}

.lnir-thumbs-up::before {
  content: $lnir-thumbs-up;
}

.lnir-thunderbolt::before {
  content: $lnir-thunderbolt;
}

.lnir-ticket-alt-1::before {
  content: $lnir-ticket-alt-1;
}

.lnir-ticket-alt-2::before {
  content: $lnir-ticket-alt-2;
}

.lnir-ticket-alt-3::before {
  content: $lnir-ticket-alt-3;
}

.lnir-ticket-alt::before {
  content: $lnir-ticket-alt;
}

.lnir-ticket::before {
  content: $lnir-ticket;
}

.lnir-unlock::before {
  content: $lnir-unlock;
}

.lnir-warning::before {
  content: $lnir-warning;
}

.lnir-backward-alt::before {
  content: $lnir-backward-alt;
}

.lnir-backward::before {
  content: $lnir-backward;
}

.lnir-expand-alt-1::before {
  content: $lnir-expand-alt-1;
}

.lnir-fast-forward::before {
  content: $lnir-fast-forward;
}

.lnir-film::before {
  content: $lnir-film;
}

.lnir-forward::before {
  content: $lnir-forward;
}

.lnir-frame-expand::before {
  content: $lnir-frame-expand;
}

.lnir-full-screen::before {
  content: $lnir-full-screen;
}

.lnir-gallery::before {
  content: $lnir-gallery;
}

.lnir-next::before {
  content: $lnir-next;
}

.lnir-pause::before {
  content: $lnir-pause;
}

.lnir-play::before {
  content: $lnir-play;
}

.lnir-previous::before {
  content: $lnir-previous;
}

.lnir-repeat-one::before {
  content: $lnir-repeat-one;
}

.lnir-shuffle::before {
  content: $lnir-shuffle;
}

.lnir-slideshow::before {
  content: $lnir-slideshow;
}

.lnir-stop::before {
  content: $lnir-stop;
}

.lnir-video-alt-1::before {
  content: $lnir-video-alt-1;
}

.lnir-video-alt::before {
  content: $lnir-video-alt;
}

.lnir-video-camera-alt-1::before {
  content: $lnir-video-camera-alt-1;
}

.lnir-video-camera-alt-2::before {
  content: $lnir-video-camera-alt-2;
}

.lnir-video-camera-alt::before {
  content: $lnir-video-camera-alt;
}

.lnir-video-cut::before {
  content: $lnir-video-cut;
}

.lnir-video::before {
  content: $lnir-video;
}

.lnir-volume-high::before {
  content: $lnir-volume-high;
}

.lnir-volume-low::before {
  content: $lnir-volume-low;
}

.lnir-volume-medium::before {
  content: $lnir-volume-medium;
}

.lnir-volume-mute::before {
  content: $lnir-volume-mute;
}

.lnir-volume::before {
  content: $lnir-volume;
}

.lnir-bank::before {
  content: $lnir-bank;
}

.lnir-calculator-alt::before {
  content: $lnir-calculator-alt;
}

.lnir-calculator::before {
  content: $lnir-calculator;
}

.lnir-coin::before {
  content: $lnir-coin;
}

.lnir-coins::before {
  content: $lnir-coins;
}

.lnir-credit-card::before {
  content: $lnir-credit-card;
}

.lnir-credit-cards::before {
  content: $lnir-credit-cards;
}

.lnir-diamond-alt::before {
  content: $lnir-diamond-alt;
}

.lnir-diamond::before {
  content: $lnir-diamond;
}

.lnir-dollar-card::before {
  content: $lnir-dollar-card;
}

.lnir-dollar-down::before {
  content: $lnir-dollar-down;
}

.lnir-dollar-up::before {
  content: $lnir-dollar-up;
}

.lnir-dollar::before {
  content: $lnir-dollar;
}

.lnir-euro-card::before {
  content: $lnir-euro-card;
}

.lnir-euro-coin::before {
  content: $lnir-euro-coin;
}

.lnir-euro-down::before {
  content: $lnir-euro-down;
}

.lnir-euro-up::before {
  content: $lnir-euro-up;
}

.lnir-euro::before {
  content: $lnir-euro;
}

.lnir-gold-bar::before {
  content: $lnir-gold-bar;
}

.lnir-mobile-coin-taka::before {
  content: $lnir-mobile-coin-taka;
}

.lnir-mobile-coin::before {
  content: $lnir-mobile-coin;
}

.lnir-money-bag::before {
  content: $lnir-money-bag;
}

.lnir-money-location::before {
  content: $lnir-money-location;
}

.lnir-money-protection::before {
  content: $lnir-money-protection;
}

.lnir-pound-card::before {
  content: $lnir-pound-card;
}

.lnir-pound-coin::before {
  content: $lnir-pound-coin;
}

.lnir-pound-down::before {
  content: $lnir-pound-down;
}

.lnir-pound-up::before {
  content: $lnir-pound-up;
}

.lnir-pound::before {
  content: $lnir-pound;
}

.lnir-rupee-card::before {
  content: $lnir-rupee-card;
}

.lnir-rupee-coin::before {
  content: $lnir-rupee-coin;
}

.lnir-text-format::before {
  content: $lnir-text-format;
}

.lnir-rupee-down::before {
  content: $lnir-rupee-down;
}

.lnir-bold::before {
  content: $lnir-bold;
}

.lnir-taka-card::before {
  content: $lnir-taka-card;
}

.lnir-taka-coin::before {
  content: $lnir-taka-coin;
}

.lnir-strikethrough::before {
  content: $lnir-strikethrough;
}

.lnir-taka-down::before {
  content: $lnir-taka-down;
}

.lnir-rupee::before {
  content: $lnir-rupee;
}

.lnir-taka-up::before {
  content: $lnir-taka-up;
}

.lnir-taka::before {
  content: $lnir-taka;
}

.lnir-rupee-up::before {
  content: $lnir-rupee-up;
}

.lnir-wallet-alt-1::before {
  content: $lnir-wallet-alt-1;
}

.lnir-wallet::before {
  content: $lnir-wallet;
}

.lnir-yen-card::before {
  content: $lnir-yen-card;
}

.lnir-yen-coin::before {
  content: $lnir-yen-coin;
}

.lnir-yen-up::before {
  content: $lnir-yen-up;
}

.lnir-bug-alt::before {
  content: $lnir-bug-alt;
}

.lnir-yen-down::before {
  content: $lnir-yen-down;
}

.lnir-yen::before {
  content: $lnir-yen;
}

.lnir-cactus-alt::before {
  content: $lnir-cactus-alt;
}

.lnir-flower::before {
  content: $lnir-flower;
}

.lnir-bug::before {
  content: $lnir-bug;
}

.lnir-cactus::before {
  content: $lnir-cactus;
}

.lnir-island::before {
  content: $lnir-island;
}

.lnir-leaf-alt::before {
  content: $lnir-leaf-alt;
}

.lnir-leaf::before {
  content: $lnir-leaf;
}

.lnir-leaves::before {
  content: $lnir-leaves;
}

.lnir-mashroom::before {
  content: $lnir-mashroom;
}

.lnir-mountain::before {
  content: $lnir-mountain;
}

.lnir-mushroom-alt::before {
  content: $lnir-mushroom-alt;
}

.lnir-mushrooms::before {
  content: $lnir-mushrooms;
}

.lnir-plant-pot::before {
  content: $lnir-plant-pot;
}

.lnir-plant::before {
  content: $lnir-plant;
}

.lnir-sprout-alt::before {
  content: $lnir-sprout-alt;
}

.lnir-sprout::before {
  content: $lnir-sprout;
}

.lnir-tree-alt-1::before {
  content: $lnir-tree-alt-1;
}

.lnir-tree-alt::before {
  content: $lnir-tree-alt;
}

.lnir-tree::before {
  content: $lnir-tree;
}

.lnir-trees-alt-1::before {
  content: $lnir-trees-alt-1;
}

.lnir-trees-alt::before {
  content: $lnir-trees-alt;
}

.lnir-trees::before {
  content: $lnir-trees;
}

.lnir-baloon-alt-1::before {
  content: $lnir-baloon-alt-1;
}

.lnir-baloon-alt::before {
  content: $lnir-baloon-alt;
}

.lnir-baloon::before {
  content: $lnir-baloon;
}

.lnir-birthday-hat::before {
  content: $lnir-birthday-hat;
}

.lnir-cake::before {
  content: $lnir-cake;
}

.lnir-leaf-alt-1::before {
  content: $lnir-leaf-alt-1;
}

.lnir-candy-cane::before {
  content: $lnir-candy-cane;
}

.lnir-tornado::before {
  content: $lnir-tornado;
}

.lnir-candy::before {
  content: $lnir-candy;
}

.lnir-confetti::before {
  content: $lnir-confetti;
}

.lnir-firecracker::before {
  content: $lnir-firecracker;
}

.lnir-fireworks::before {
  content: $lnir-fireworks;
}

.lnir-flags::before {
  content: $lnir-flags;
}

.lnir-mask::before {
  content: $lnir-mask;
}

.lnir-spray::before {
  content: $lnir-spray;
}

.lnir-wand::before {
  content: $lnir-wand;
}

.lnir-cotton-bud::before {
  content: $lnir-cotton-bud;
}

.lnir-crown-alt-1::before {
  content: $lnir-crown-alt-1;
}

.lnir-crown-alt::before {
  content: $lnir-crown-alt;
}

.lnir-crown-alt-2::before {
  content: $lnir-crown-alt-2;
}

.lnir-crown::before {
  content: $lnir-crown;
}

.lnir-cup-alt::before {
  content: $lnir-cup-alt;
}

.lnir-flower-alt::before {
  content: $lnir-flower-alt;
}

.lnir-gift-alt-1::before {
  content: $lnir-gift-alt-1;
}

.lnir-gift-alt::before {
  content: $lnir-gift-alt;
}

.lnir-gift-bag::before {
  content: $lnir-gift-bag;
}

.lnir-gift::before {
  content: $lnir-gift;
}

.lnir-glasses::before {
  content: $lnir-glasses;
}

.lnir-home::before {
  content: $lnir-home;
}

.lnir-ladies-tshirt::before {
  content: $lnir-ladies-tshirt;
}

.lnir-sunglass::before {
  content: $lnir-sunglass;
}

.lnir-thought::before {
  content: $lnir-thought;
}

.lnir-tshirt-alt-1::before {
  content: $lnir-tshirt-alt-1;
}

.lnir-tshirt-alt::before {
  content: $lnir-tshirt-alt;
}

.lnir-tshirt::before {
  content: $lnir-tshirt;
}

.lnir-beach-chair::before {
  content: $lnir-beach-chair;
}

.lnir-beach::before {
  content: $lnir-beach;
}

.lnir-beachball::before {
  content: $lnir-beachball;
}

.lnir-cable-car::before {
  content: $lnir-cable-car;
}

.lnir-flight-search::before {
  content: $lnir-flight-search;
}

.lnir-hot-air-baloon::before {
  content: $lnir-hot-air-baloon;
}

.lnir-pyramids::before {
  content: $lnir-pyramids;
}

.lnir-suitcase-alt::before {
  content: $lnir-suitcase-alt;
}

.lnir-suitcase::before {
  content: $lnir-suitcase;
}

.lnir-surf-board::before {
  content: $lnir-surf-board;
}

.lnir-tent-alt::before {
  content: $lnir-tent-alt;
}

.lnir-tent::before {
  content: $lnir-tent;
}

.lnir-travel::before {
  content: $lnir-travel;
}

.lnir-air-flow::before {
  content: $lnir-air-flow;
}

.lnir-air::before {
  content: $lnir-air;
}

.lnir-cloud-alt-1::before {
  content: $lnir-cloud-alt-1;
}

.lnir-cloud-alt-2::before {
  content: $lnir-cloud-alt-2;
}

.lnir-cloud-alt::before {
  content: $lnir-cloud-alt;
}

.lnir-cloud-moon::before {
  content: $lnir-cloud-moon;
}

.lnir-cloud-sun::before {
  content: $lnir-cloud-sun;
}

.lnir-cloud::before {
  content: $lnir-cloud;
}

.lnir-cloudy-sun::before {
  content: $lnir-cloudy-sun;
}

.lnir-drop-alt::before {
  content: $lnir-drop-alt;
}

.lnir-drop::before {
  content: $lnir-drop;
}

.lnir-farenheit::before {
  content: $lnir-farenheit;
}

.lnir-moon-star::before {
  content: $lnir-moon-star;
}

.lnir-moon::before {
  content: $lnir-moon;
}

.lnir-night-thunder::before {
  content: $lnir-night-thunder;
}

.lnir-night-wind::before {
  content: $lnir-night-wind;
}

.lnir-night::before {
  content: $lnir-night;
}

.lnir-rain::before {
  content: $lnir-rain;
}

.lnir-snowfall::before {
  content: $lnir-snowfall;
}

.lnir-sun-rain::before {
  content: $lnir-sun-rain;
}

.lnir-sun::before {
  content: $lnir-sun;
}

.lnir-thunder-alt::before {
  content: $lnir-thunder-alt;
}

.lnir-thunder::before {
  content: $lnir-thunder;
}

.lnir-umbrella-alt::before {
  content: $lnir-umbrella-alt;
}

.lnir-umbrella-rain-alt::before {
  content: $lnir-umbrella-rain-alt;
}

.lnir-umbrella-rain::before {
  content: $lnir-umbrella-rain;
}

.lnir-umbrella::before {
  content: $lnir-umbrella;
}

.lnir-water-drops::before {
  content: $lnir-water-drops;
}

.lnir-wind-cloud::before {
  content: $lnir-wind-cloud;
}

.lnir-wind-sun::before {
  content: $lnir-wind-sun;
}

.lnir-hourglass::before {
  content: $lnir-hourglass;
}

.lnir-spiner-solid::before {
  content: $lnir-spiner-solid;
}

.lnir-spinner-1::before {
  content: $lnir-spinner-1;
}

.lnir-spinner-10::before {
  content: $lnir-spinner-10;
}

.lnir-cup::before {
  content: $lnir-cup;
}

.lnir-spinner-11::before {
  content: $lnir-spinner-11;
}

.lnir-spinner-5::before {
  content: $lnir-spinner-5;
}

.lnir-spinner-6::before {
  content: $lnir-spinner-6;
}

.lnir-spinner-2::before {
  content: $lnir-spinner-2;
}

.lnir-spinner-3::before {
  content: $lnir-spinner-3;
}

.lnir-spinner-7::before {
  content: $lnir-spinner-7;
}

.lnir-spinner-8::before {
  content: $lnir-spinner-8;
}

.lnir-spinner-9::before {
  content: $lnir-spinner-9;
}

.lnir-spinner-arrow::before {
  content: $lnir-spinner-arrow;
}

.lnir-spinner::before {
  content: $lnir-spinner;
}

.lnir-3d::before {
  content: $lnir-3d;
}

.lnir-add-files::before {
  content: $lnir-add-files;
}

.lnir-ai::before {
  content: $lnir-ai;
}

.lnir-alarm-2::before {
  content: $lnir-alarm-2;
}

.lnir-alarm-3::before {
  content: $lnir-alarm-3;
}

.lnir-alarm-4::before {
  content: $lnir-alarm-4;
}

.lnir-alarm-clock-alt::before {
  content: $lnir-alarm-clock-alt;
}

.lnir-alarm-clock::before {
  content: $lnir-alarm-clock;
}

.lnir-alarm::before {
  content: $lnir-alarm;
}

.lnir-candle-alt::before {
  content: $lnir-candle-alt;
}

.lnir-spinner-4::before {
  content: $lnir-spinner-4;
}

.lnir-archive::before {
  content: $lnir-archive;
}

.lnir-backspace::before {
  content: $lnir-backspace;
}

.lnir-battery::before {
  content: $lnir-battery;
}

.lnir-block-user::before {
  content: $lnir-block-user;
}

.lnir-bluetooth::before {
  content: $lnir-bluetooth;
}

.lnir-bookmark-alt::before {
  content: $lnir-bookmark-alt;
}

.lnir-bookmark::before {
  content: $lnir-bookmark;
}

.lnir-brightness::before {
  content: $lnir-brightness;
}

.lnir-bulb-alt::before {
  content: $lnir-bulb-alt;
}

.lnir-bulb::before {
  content: $lnir-bulb;
}

.lnir-calendar::before {
  content: $lnir-calendar;
}

.lnir-calender-alt-1::before {
  content: $lnir-calender-alt-1;
}

.lnir-calender-alt-2::before {
  content: $lnir-calender-alt-2;
}

.lnir-calender-alt-3::before {
  content: $lnir-calender-alt-3;
}

.lnir-calender-alt-4::before {
  content: $lnir-calender-alt-4;
}

.lnir-calender-alt::before {
  content: $lnir-calender-alt;
}

.lnir-camera-alt-1::before {
  content: $lnir-camera-alt-1;
}

.lnir-camera-alt::before {
  content: $lnir-camera-alt;
}

.lnir-camera::before {
  content: $lnir-camera;
}

.lnir-change::before {
  content: $lnir-change;
}

.lnir-charging::before {
  content: $lnir-charging;
}

.lnir-cloud-check-alt::before {
  content: $lnir-cloud-check-alt;
}

.lnir-cloud-check::before {
  content: $lnir-cloud-check;
}

.lnir-cloud-computing-alt::before {
  content: $lnir-cloud-computing-alt;
}

.lnir-cloud-computing::before {
  content: $lnir-cloud-computing;
}

.lnir-cloud-download-alt::before {
  content: $lnir-cloud-download-alt;
}

.lnir-cloud-download::before {
  content: $lnir-cloud-download;
}

.lnir-cloud-network-2::before {
  content: $lnir-cloud-network-2;
}

.lnir-cloud-network::before {
  content: $lnir-cloud-network;
}

.lnir-cloud-search-alt-1::before {
  content: $lnir-cloud-search-alt-1;
}

.lnir-cloud-search-alt::before {
  content: $lnir-cloud-search-alt;
}

.lnir-cloud-search::before {
  content: $lnir-cloud-search;
}

.lnir-cloud-sync-alt::before {
  content: $lnir-cloud-sync-alt;
}

.lnir-cloud-sync::before {
  content: $lnir-cloud-sync;
}

.lnir-cloud-upload-alt::before {
  content: $lnir-cloud-upload-alt;
}

.lnir-cloud-upload::before {
  content: $lnir-cloud-upload;
}

.lnir-code-alt::before {
  content: $lnir-code-alt;
}

.lnir-code::before {
  content: $lnir-code;
}

.lnir-cog::before {
  content: $lnir-cog;
}

.lnir-cogs::before {
  content: $lnir-cogs;
}

.lnir-control-panel::before {
  content: $lnir-control-panel;
}

.lnir-copy::before {
  content: $lnir-copy;
}

.lnir-cut::before {
  content: $lnir-cut;
}

.lnir-dashboard::before {
  content: $lnir-dashboard;
}

.lnir-database::before {
  content: $lnir-database;
}

.lnir-display-alt::before {
  content: $lnir-display-alt;
}

.lnir-display::before {
  content: $lnir-display;
}

.lnir-domain::before {
  content: $lnir-domain;
}

.lnir-download::before {
  content: $lnir-download;
}

.lnir-empty-battery::before {
  content: $lnir-empty-battery;
}

.lnir-empty-file::before {
  content: $lnir-empty-file;
}

.lnir-file-download::before {
  content: $lnir-file-download;
}

.lnir-file-lock::before {
  content: $lnir-file-lock;
}

.lnir-file-name::before {
  content: $lnir-file-name;
}

.lnir-file-protection::before {
  content: $lnir-file-protection;
}

.lnir-file-upload::before {
  content: $lnir-file-upload;
}

.lnir-files-alt::before {
  content: $lnir-files-alt;
}

.lnir-files::before {
  content: $lnir-files;
}

.lnir-folder-alt-1::before {
  content: $lnir-folder-alt-1;
}

.lnir-folder-alt::before {
  content: $lnir-folder-alt;
}

.lnir-game::before {
  content: $lnir-game;
}

.lnir-folder::before {
  content: $lnir-folder;
}

.lnir-half-battery::before {
  content: $lnir-half-battery;
}

.lnir-headphone::before {
  content: $lnir-headphone;
}

.lnir-image::before {
  content: $lnir-image;
}

.lnir-keyboard::before {
  content: $lnir-keyboard;
}

.lnir-laptop-alt-keyboard::before {
  content: $lnir-laptop-alt-keyboard;
}

.lnir-laptop-alt-switch::before {
  content: $lnir-laptop-alt-switch;
}

.lnir-laptop-alt::before {
  content: $lnir-laptop-alt;
}

.lnir-laptop-phone::before {
  content: $lnir-laptop-phone;
}

.lnir-laptop::before {
  content: $lnir-laptop;
}

.lnir-link-alt-1::before {
  content: $lnir-link-alt-1;
}

.lnir-link-alt::before {
  content: $lnir-link-alt;
}

.lnir-link::before {
  content: $lnir-link;
}

.lnir-low-battery::before {
  content: $lnir-low-battery;
}

.lnir-magnet::before {
  content: $lnir-magnet;
}

.lnir-magnifier::before {
  content: $lnir-magnifier;
}

.lnir-map-marker::before {
  content: $lnir-map-marker;
}

.lnir-map::before {
  content: $lnir-map;
}

.lnir-menu-alt-1::before {
  content: $lnir-menu-alt-1;
}

.lnir-menu-alt-2::before {
  content: $lnir-menu-alt-2;
}

.lnir-menu-alt-3::before {
  content: $lnir-menu-alt-3;
}

.lnir-menu-alt-4::before {
  content: $lnir-menu-alt-4;
}

.lnir-menu-alt-5::before {
  content: $lnir-menu-alt-5;
}

.lnir-menu-alt::before {
  content: $lnir-menu-alt;
}

.lnir-menu-circle::before {
  content: $lnir-menu-circle;
}

.lnir-menu::before {
  content: $lnir-menu;
}

.lnir-mic::before {
  content: $lnir-mic;
}

.lnir-microphone-alt-1::before {
  content: $lnir-microphone-alt-1;
}

.lnir-microphone-alt::before {
  content: $lnir-microphone-alt;
}

.lnir-microphone::before {
  content: $lnir-microphone;
}

.lnir-mobile-alt-1::before {
  content: $lnir-mobile-alt-1;
}

.lnir-mobile-alt-2::before {
  content: $lnir-mobile-alt-2;
}

.lnir-harddrive::before {
  content: $lnir-harddrive;
}

.lnir-mobile-alt-button::before {
  content: $lnir-mobile-alt-button;
}

.lnir-mobile::before {
  content: $lnir-mobile;
}

.lnir-headphone-alt::before {
  content: $lnir-headphone-alt;
}

.lnir-full-battery::before {
  content: $lnir-full-battery;
}

.lnir-mouse::before {
  content: $lnir-mouse;
}

.lnir-no-charge-battery::before {
  content: $lnir-no-charge-battery;
}

.lnir-package::before {
  content: $lnir-package;
}

.lnir-plug::before {
  content: $lnir-plug;
}

.lnir-presentation::before {
  content: $lnir-presentation;
}

.lnir-printer::before {
  content: $lnir-printer;
}

.lnir-ps::before {
  content: $lnir-ps;
}

.lnir-recycle-alt::before {
  content: $lnir-recycle-alt;
}

.lnir-recycle::before {
  content: $lnir-recycle;
}

.lnir-reload-alt-box::before {
  content: $lnir-reload-alt-box;
}

.lnir-reload-alt::before {
  content: $lnir-reload-alt;
}

.lnir-reload::before {
  content: $lnir-reload;
}

.lnir-remove-file::before {
  content: $lnir-remove-file;
}

.lnir-rocket::before {
  content: $lnir-rocket;
}

.lnir-rss-feed::before {
  content: $lnir-rss-feed;
}

.lnir-save-alt::before {
  content: $lnir-save-alt;
}

.lnir-save::before {
  content: $lnir-save;
}

.lnir-scan::before {
  content: $lnir-scan;
}

.lnir-scroll-down::before {
  content: $lnir-scroll-down;
}

.lnir-search-alt::before {
  content: $lnir-search-alt;
}

.lnir-search::before {
  content: $lnir-search;
}

.lnir-share-alt::before {
  content: $lnir-share-alt;
}

.lnir-share::before {
  content: $lnir-share;
}

.lnir-shopping-basket::before {
  content: $lnir-shopping-basket;
}

.lnir-shortcode::before {
  content: $lnir-shortcode;
}

.lnir-signal::before {
  content: $lnir-signal;
}

.lnir-star-empty::before {
  content: $lnir-star-empty;
}

.lnir-star-filled::before {
  content: $lnir-star-filled;
}

.lnir-star-half::before {
  content: $lnir-star-half;
}

.lnir-star::before {
  content: $lnir-star;
}

.lnir-tab::before {
  content: $lnir-tab;
}

.lnir-tap::before {
  content: $lnir-tap;
}

.lnir-thumbnail::before {
  content: $lnir-thumbnail;
}

.lnir-timer::before {
  content: $lnir-timer;
}

.lnir-trash-can-alt-1::before {
  content: $lnir-trash-can-alt-1;
}

.lnir-trash-can-alt::before {
  content: $lnir-trash-can-alt;
}

.lnir-trash-can::before {
  content: $lnir-trash-can;
}

.lnir-trash::before {
  content: $lnir-trash;
}

.lnir-unlink::before {
  content: $lnir-unlink;
}

.lnir-upload::before {
  content: $lnir-upload;
}

.lnir-user-alt-1::before {
  content: $lnir-user-alt-1;
}

.lnir-user-alt-2::before {
  content: $lnir-user-alt-2;
}

.lnir-user-alt::before {
  content: $lnir-user-alt;
}

.lnir-user::before {
  content: $lnir-user;
}

.lnir-users-2::before {
  content: $lnir-users-2;
}

.lnir-users::before {
  content: $lnir-users;
}

.lnir-website-alt::before {
  content: $lnir-website-alt;
}

.lnir-website::before {
  content: $lnir-website;
}

.lnir-zip::before {
  content: $lnir-zip;
}

.lnir-ban::before {
  content: $lnir-ban;
}

.lnir-candle::before {
  content: $lnir-candle;
}

.lnir-appointment::before {
  content: $lnir-appointment;
}

.lnir-zoom-in::before {
  content: $lnir-zoom-in;
}

.lnir-cool-1::before {
  content: $lnir-cool-1;
}

.lnir-cool-3::before {
  content: $lnir-cool-3;
}

.lnir-cool-kiss::before {
  content: $lnir-cool-kiss;
}

.lnir-cry::before {
  content: $lnir-cry;
}

.lnir-cute::before {
  content: $lnir-cute;
}

.lnir-dead::before {
  content: $lnir-dead;
}

.lnir-bored::before {
  content: $lnir-bored;
}

.lnir-emoji-friendly::before {
  content: $lnir-emoji-friendly;
}

.lnir-emoji-happy::before {
  content: $lnir-emoji-happy;
}

.lnir-emoji-sad::before {
  content: $lnir-emoji-sad;
}

.lnir-emoji-cool::before {
  content: $lnir-emoji-cool;
}

.lnir-emoji-speechless::before {
  content: $lnir-emoji-speechless;
}

.lnir-emoji-suspect::before {
  content: $lnir-emoji-suspect;
}

.lnir-emoji-tounge::before {
  content: $lnir-emoji-tounge;
}

.lnir-emoji-smile::before {
  content: $lnir-emoji-smile;
}

.lnir-happy-2::before {
  content: $lnir-happy-2;
}

.lnir-happy-smile::before {
  content: $lnir-happy-smile;
}

.lnir-kiss-2::before {
  content: $lnir-kiss-2;
}

.lnir-kiss::before {
  content: $lnir-kiss;
}

.lnir-angry::before {
  content: $lnir-angry;
}

.lnir-loved-surprise::before {
  content: $lnir-loved-surprise;
}

.lnir-zoom-out::before {
  content: $lnir-zoom-out;
}

.lnir-piss::before {
  content: $lnir-piss;
}

.lnir-sick-1::before {
  content: $lnir-sick-1;
}

.lnir-sick::before {
  content: $lnir-sick;
}

.lnir-mute::before {
  content: $lnir-mute;
}

.lnir-super-cool::before {
  content: $lnir-super-cool;
}

.lnir-love::before {
  content: $lnir-love;
}

.lnir-wink::before {
  content: $lnir-wink;
}

.lnir-wow::before {
  content: $lnir-wow;
}

.lnir-yawn::before {
  content: $lnir-yawn;
}

.lnir-super-happy::before {
  content: $lnir-super-happy;
}

.lnir-happy-1::before {
  content: $lnir-happy-1;
}

.lnir-sleep::before {
  content: $lnir-sleep;
}

