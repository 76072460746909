@import "../../variable";



.primary-bg{
    background: $primary;
    color: $bright;
}
ul.planInfoList{
    display:flex;
    flex-wrap: wrap;
    padding-left:0;
    list-style-position: inside;
    li{
        width:50%;
        padding-right:10px;
        font-family: 'cerebri_sanssemibold';
        label{
            color:#666666;
            font-family: 'cerebri_sansregular';
        }

    }
}