@import "../../variable";

.filterLink{
    a{
        font-size:12px;
        color: #999999;
        display: inline-block;
        padding:5px 10px;
        &.active{
            color:#ffa800
        }
    }
}

.playGroup.topChart{
    border-bottom:0 none;
    .count{
        font-size:23px;
    }
    .infoMid{
        order: 4;
        width: 100%;
        padding-top: 20px;
        display:none !important;
    }
    .expandPlaylist{
        padding-top:10px;
        display:none;
        .song{
            padding-left:13px;
        }
    }    
}

.topChartSongsBox{
    background-color:#f0f1f6
}