@import "../../variable";

.navSide{
    min-height: 100vh;
    padding-bottom:110px;

    .nav-link{
        padding:14px 40px;
        font-size:16px;
        color: $dark;
        border-right:3px solid transparent;
        margin-right:-1px;
        &:hover{
            color: $primary;
        }
        &.active{
            color: $primary;
            border-right-color:$primary;
        }
            i{
                margin-right:20px;
            }
    }
    .navTitle{
        padding:0px 40px 15px 40px;
        text-transform: uppercase;
        font-size:12px;
        letter-spacing: 1.6px;
        color:$lightGray;
    }
}

.btn-premium-contain{
    padding:0px 40px 20px 40px;
}

.logo{
    padding:40px 40px 0px 40px;
}
.btn.premium{
    text-transform: uppercase;
    font-size:14px;
    padding:0 15px;
    height:60px;
    border-radius:5px;
    line-height:60px;
    border:0;
}


@media only screen and (max-width: 1700px) {
    .logo{
        padding:20px 20px 0px 20px;
    }
    .btn-premium-contain{
        padding:0 20px 10px 20px;
    }
    .navSideContain{
        width: 220px;
    }
    .navSide .nav-link{
        font-size:15px;
        padding: 12px 20px;
        i{
            margin-right:10px;
        }
    } 
}


body.dark-theme{
    .navSide .nav-link{
        color: #ffffff;
    }
    .border-right{
        border-right-color:#3e3e3e !important;
    }
}