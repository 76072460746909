@import "../../variable";



.albumContain{
    padding:10px 20px 10px 0px ;
}

.sliderBox{
    max-height:442px;
    // overflow: hidden;
}

.albumBox{
    background-color:$gray-200;
    padding-top:100%;
    border-radius:8px;
    overflow: hidden;
    position: relative;
    background-size: cover;

    .hoverAction{
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color:rgba($color: #000000, $alpha: .75);
        display: none ;
        padding:0 20px 20px 20px;
        .playBtn{
            width:42px;
            height:42px;
            background-color:$primary;
            text-align: center;
            line-height:44px;
            .play-icon{
                width:14px;
                height:15px;
                margin-left:1px;
                &::before{
                    border-width:7px 0 7px 15px;
                    border-color: transparent transparent transparent #ffffff;
                }
            }
        }
        .dropNav{
            button.btn{
                background-color: transparent;
                border: 0 none;
                &:focus{
                    box-shadow: none;
                }
                .lnir.lnir-more-alt{
                    font-size:18px;
                }
            }
            .dropdown-toggle::after{
                display: none;
            }
        }

    }

    &:hover{
        .hoverAction{
            display: flex;
        }
    }

}


.spotlightSlide{
    .col-4{
        padding-left:10px;
        padding-right:10px;
    }
    .row{
        margin-left:-10px;
        margin-right:-10px;
    }
    .col-5{
        padding-left:0px ;
        padding-right:20px;
        flex: 0 0 40.7%;
        max-width:40.7%;
    }
    .col-7{
        flex: 0 0 59.3%;
        max-width: 59.3%;
    }
    .largeSide{
        flex: 0 0 40.8%;
    padding-right: 20px;
    }
}

