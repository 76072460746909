@import "../../variable";

.content-header{
    ul.nav{
        li{
            margin-left:30px;
            a{
                color: #333333;
                padding:10px 0;
                &:hover{
                    color: $primary;
                    text-decoration: none;
                }
            }
        }
    }
}