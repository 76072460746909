@import "../../variable";

.RightBar{
    background-color:#f2f3f8;
    padding:40px 40px 120px 40px;
    > .d-flex{
        flex-direction: column  ;
        aside:first-child{
            margin-bottom:80px;
        }
    }
}

.playCategory{
    border-bottom:1px solid #dddddd;
    .title {

        &.active{
            color: $primary;
        }
    .tile{
        font-size:19px;
        font-weight:500;
    }
    }
    .content{
        ul{
            list-style: none;
            padding: 0;
            li{
                a{
                    display: block;
                    padding:5px 0;
                }
            }
        }
    }
}

@media only screen and (max-width: 1700px) {
    .RightBar{
        padding:20px;
    }
}

@media only screen and (max-width: 1400px) {
    .RightBar{
        margin-top: 30px;
        overflow: hidden;
        > .d-flex{
            flex-direction: row ;
            margin:0 -50px;
            > aside{
                flex: 1;
                padding:0 50px;
                &:first-child{
                    margin-bottom:0px;
                    border-right:1px solid #e2e2e2;
                }
            }
        }
    }
}
@media only screen and (max-width: 992px) {
    .RightBar{
        > .d-flex{
            flex-direction: column ;
            margin:0px;
            > aside{
                padding:0px;
                &:first-child{
                    margin-bottom:50px;
                    border-right:0 none;
                }
            }
        }
    }
}

