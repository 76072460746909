//bootstrap variable customization
$primary: #ffa800 !default;
$dark: #000000 !default;
$bright: #ffffff !default;
$lightGray: #999999 !default;
$darkGray: #666666 !default;


$h3-font-size:                24px !default;
$h6-font-size:                15px !default;

$gray-200: #e9ecef !default;


@mixin lnir{
    display: inline-block;
    font: normal normal normal 1em/1 'LineIconsPro Regular';
    speak: none;
    text-transform: none;
    /* Better Font Rendering */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

// icon variable

@function unicode($str) {
    @return unquote("\"\\#{$str}\"");
  }
  
  $lnir-500px: unicode(ea02);
  $lnir-adobe: unicode(ea03);
  $lnir-airbnb: unicode(ea04);
  $lnir-amazon-original: unicode(ea05);
  $lnir-amazon-pay: unicode(ea06);
  $lnir-amazon: unicode(ea07);
  $lnir-angellist: unicode(ea08);
  $lnir-android-original: unicode(ea09);
  $lnir-android: unicode(ea0a);
  $lnir-amex: unicode(ea0b);
  $lnir-angular: unicode(ea0c);
  $lnir-atlassian: unicode(ea0d);
  $lnir-apple-pay: unicode(ea0e);
  $lnir-apple: unicode(ea0f);
  $lnir-app-store: unicode(ea10);
  $lnir-aws: unicode(ea11);
  $lnir-behance-original: unicode(ea12);
  $lnir-behance: unicode(ea13);
  $lnir-bitbucket: unicode(ea14);
  $lnir-chrome: unicode(ea15);
  $lnir-bitcoin: unicode(ea16);
  $lnir-codepen: unicode(ea17);
  $lnir-bootstrap: unicode(ea18);
  $lnir-css3: unicode(ea19);
  $lnir-blogger: unicode(ea1a);
  $lnir-dev: unicode(ea1b);
  $lnir-diners-club: unicode(ea1c);
  $lnir-discord: unicode(ea1d);
  $lnir-discover: unicode(ea1e);
  $lnir-docker: unicode(ea1f);
  $lnir-dribbble: unicode(ea20);
  $lnir-dropbox: unicode(ea21);
  $lnir-drupal-original: unicode(ea22);
  $lnir-drupal: unicode(ea23);
  $lnir-edge: unicode(ea24);
  $lnir-envato: unicode(ea25);
  $lnir-facebook-filled: unicode(ea26);
  $lnir-cpanel: unicode(ea27);
  $lnir-creative-commons: unicode(ea28);
  $lnir-facebook-messenger: unicode(ea29);
  $lnir-facebook-original: unicode(ea2a);
  $lnir-facebook-oval: unicode(ea2b);
  $lnir-facebook: unicode(ea2c);
  $lnir-figma: unicode(ea2d);
  $lnir-firefox-original: unicode(ea2e);
  $lnir-firefox: unicode(ea2f);
  $lnir-flickr: unicode(ea30);
  $lnir-git: unicode(ea31);
  $lnir-github-original: unicode(ea32);
  $lnir-github: unicode(ea33);
  $lnir-goodreads: unicode(ea34);
  $lnir-google-drive: unicode(ea35);
  $lnir-google-pay: unicode(ea36);
  $lnir-google-wallet: unicode(ea37);
  $lnir-google: unicode(ea38);
  $lnir-hacker-news: unicode(ea39);
  $lnir-html5: unicode(ea3a);
  $lnir-instagram-filled: unicode(ea3b);
  $lnir-instagram-original: unicode(ea3c);
  $lnir-instagram: unicode(ea3d);
  $lnir-javascript: unicode(ea3e);
  $lnir-jcb: unicode(ea3f);
  $lnir-joomla: unicode(ea40);
  $lnir-laravel: unicode(ea41);
  $lnir-lineicons-alt: unicode(ea42);
  $lnir-dropbox-original: unicode(ea43);
  $lnir-linkedin-original: unicode(ea44);
  $lnir-mailchimp: unicode(ea45);
  $lnir-mastercard: unicode(ea46);
  $lnir-medium: unicode(ea47);
  $lnir-megento: unicode(ea48);
  $lnir-microsoft: unicode(ea49);
  $lnir-nodejs-alt: unicode(ea4a);
  $lnir-nodejs: unicode(ea4b);
  $lnir-npm: unicode(ea4c);
  $lnir-opera: unicode(ea4d);
  $lnir-patreon: unicode(ea4e);
  $lnir-paypal-original: unicode(ea4f);
  $lnir-paypal: unicode(ea50);
  $lnir-php: unicode(ea51);
  $lnir-line: unicode(ea52);
  $lnir-lineicons: unicode(ea53);
  $lnir-linkedin: unicode(ea54);
  $lnir-pinterest: unicode(ea55);
  $lnir-play-store: unicode(ea56);
  $lnir-producthunt: unicode(ea57);
  $lnir-python: unicode(ea58);
  $lnir-quora: unicode(ea59);
  $lnir-react: unicode(ea5a);
  $lnir-reddit: unicode(ea5b);
  $lnir-shopify: unicode(ea5c);
  $lnir-sketch: unicode(ea5d);
  $lnir-skype: unicode(ea5e);
  $lnir-slack: unicode(ea5f);
  $lnir-slideshare: unicode(ea60);
  $lnir-snapchat: unicode(ea61);
  $lnir-souncloud-original: unicode(ea62);
  $lnir-soundcloud: unicode(ea63);
  $lnir-spotify-original: unicode(ea64);
  $lnir-spotify: unicode(ea65);
  $lnir-stackoverflow: unicode(ea66);
  $lnir-swift: unicode(ea67);
  $lnir-telegram-original: unicode(ea68);
  $lnir-telegram: unicode(ea69);
  $lnir-trello: unicode(ea6a);
  $lnir-stripe: unicode(ea6b);
  $lnir-tumblr: unicode(ea6c);
  $lnir-stumbleupon: unicode(ea6d);
  $lnir-twitter-original: unicode(ea6e);
  $lnir-twitter: unicode(ea6f);
  $lnir-ubuntu: unicode(ea70);
  $lnir-vimeo: unicode(ea71);
  $lnir-twitch: unicode(ea72);
  $lnir-steam: unicode(ea73);
  $lnir-twitter-filled: unicode(ea74);
  $lnir-visa: unicode(ea75);
  $lnir-vk: unicode(ea76);
  $lnir-wechat: unicode(ea77);
  $lnir-whatsapp: unicode(ea78);
  $lnir-windows: unicode(ea79);
  $lnir-wordpress-filled: unicode(ea7a);
  $lnir-wordpress: unicode(ea7b);
  $lnir-yahoo: unicode(ea7c);
  $lnir-ycombinator: unicode(ea7d);
  $lnir-youtube: unicode(ea7e);
  $lnir-anchor: unicode(ea7f);
  $lnir-bi-cycle: unicode(ea80);
  $lnir-boarding-pass-alt: unicode(ea81);
  $lnir-boarding-pass: unicode(ea82);
  $lnir-java: unicode(ea83);
  $lnir-jsfiddle: unicode(ea84);
  $lnir-bridge: unicode(ea85);
  $lnir-bullhorn: unicode(ea86);
  $lnir-bus: unicode(ea87);
  $lnir-car-alt: unicode(ea88);
  $lnir-car: unicode(ea89);
  $lnir-caravan: unicode(ea8a);
  $lnir-chat-add: unicode(ea8b);
  $lnir-chat-minus: unicode(ea8c);
  $lnir-comments-alt: unicode(ea8d);
  $lnir-comments-reply: unicode(ea8e);
  $lnir-comments: unicode(ea8f);
  $lnir-envelope-alt: unicode(ea90);
  $lnir-helicopter: unicode(ea91);
  $lnir-inbox: unicode(ea92);
  $lnir-megaphone: unicode(ea93);
  $lnir-message-attachement: unicode(ea94);
  $lnir-message-block: unicode(ea95);
  $lnir-message-check: unicode(ea96);
  $lnir-message-cross: unicode(ea97);
  $lnir-message-desktop: unicode(ea98);
  $lnir-message-edit: unicode(ea99);
  $lnir-message-forward: unicode(ea9a);
  $lnir-message-incoming: unicode(ea9b);
  $lnir-message-laptop: unicode(ea9c);
  $lnir-message-lock: unicode(ea9d);
  $lnir-message-love: unicode(ea9e);
  $lnir-message-minus: unicode(ea9f);
  $lnir-message-outgoing: unicode(eaa0);
  $lnir-message-plus: unicode(eaa1);
  $lnir-message-reply: unicode(eaa2);
  $lnir-message-search: unicode(eaa3);
  $lnir-message-share: unicode(eaa4);
  $lnir-mobile-chat: unicode(eaa5);
  $lnir-page: unicode(eaa6);
  $lnir-phone-block: unicode(eaa7);
  $lnir-phone-caling: unicode(eaa8);
  $lnir-phone-conference: unicode(eaa9);
  $lnir-phone-cut: unicode(eaaa);
  $lnir-phone-forward: unicode(eaab);
  $lnir-phone-incoming: unicode(eaac);
  $lnir-phone-misscall: unicode(eaad);
  $lnir-phone-outgoing: unicode(eaae);
  $lnir-phone-pause: unicode(eaaf);
  $lnir-phone-redial: unicode(eab0);
  $lnir-phone-ring: unicode(eab1);
  $lnir-phone-set: unicode(eab2);
  $lnir-phone: unicode(eab3);
  $lnir-plane-alt: unicode(eab4);
  $lnir-plane: unicode(eab5);
  $lnir-popup: unicode(eab6);
  $lnir-postcard-alt: unicode(eab7);
  $lnir-postcard: unicode(eab8);
  $lnir-reply: unicode(eab9);
  $lnir-road: unicode(eaba);
  $lnir-scooter: unicode(eabb);
  $lnir-ship: unicode(eabc);
  $lnir-support: unicode(eabd);
  $lnir-target-mail: unicode(eabe);
  $lnir-taxi: unicode(eabf);
  $lnir-train-alt: unicode(eac0);
  $lnir-train: unicode(eac1);
  $lnir-boundary: unicode(eac2);
  $lnir-bricks-alt: unicode(eac3);
  $lnir-bricks: unicode(eac4);
  $lnir-bulldozer: unicode(eac5);
  $lnir-cone: unicode(eac6);
  $lnir-construction-hammer: unicode(eac7);
  $lnir-construction-rular: unicode(eac8);
  $lnir-construction: unicode(eac9);
  $lnir-door-alt: unicode(eaca);
  $lnir-drill: unicode(eacb);
  $lnir-fence: unicode(eacc);
  $lnir-hammer-alt: unicode(eacd);
  $lnir-envelope: unicode(eace);
  $lnir-joomla-original: unicode(eacf);
  $lnir-bubble: unicode(ead0);
  $lnir-door: unicode(ead1);
  $lnir-helmet: unicode(ead2);
  $lnir-hook: unicode(ead3);
  $lnir-house-alt-1: unicode(ead4);
  $lnir-house-alt: unicode(ead5);
  $lnir-house-heart: unicode(ead6);
  $lnir-house-plan: unicode(ead7);
  $lnir-house: unicode(ead8);
  $lnir-measuring-tape: unicode(ead9);
  $lnir-paint-bucket: unicode(eada);
  $lnir-paint-roller-alt-1: unicode(eadb);
  $lnir-paint-roller-alt: unicode(eadc);
  $lnir-paint-roller: unicode(eadd);
  $lnir-pipe: unicode(eade);
  $lnir-ruler-alt: unicode(eadf);
  $lnir-ruler-pencil: unicode(eae0);
  $lnir-ruler: unicode(eae1);
  $lnir-saw-alt: unicode(eae2);
  $lnir-saw: unicode(eae3);
  $lnir-screw: unicode(eae4);
  $lnir-screwdriver: unicode(eae5);
  $lnir-shovel: unicode(eae6);
  $lnir-toolbox: unicode(eae7);
  $lnir-triangle-ruler-1: unicode(eae8);
  $lnir-triangle-ruler: unicode(eae9);
  $lnir-trowel-alt: unicode(eaea);
  $lnir-trowel: unicode(eaeb);
  $lnir-wheelbarrow: unicode(eaec);
  $lnir-wrench: unicode(eaed);
  $lnir-brush-alt: unicode(eaee);
  $lnir-brush: unicode(eaef);
  $lnir-color-palette: unicode(eaf0);
  $lnir-crop-alt: unicode(eaf1);
  $lnir-crop: unicode(eaf2);
  $lnir-grid-alt: unicode(eaf3);
  $lnir-grid: unicode(eaf4);
  $lnir-highlight-alt: unicode(eaf5);
  $lnir-highlight: unicode(eaf6);
  $lnir-image-crop: unicode(eaf7);
  $lnir-layers-alt-1: unicode(eaf8);
  $lnir-layers-alt: unicode(eaf9);
  $lnir-layers: unicode(eafa);
  $lnir-layout-alt-1: unicode(eafb);
  $lnir-layout-alt-2: unicode(eafc);
  $lnir-layout-alt: unicode(eafd);
  $lnir-layout: unicode(eafe);
  $lnir-magic-wand: unicode(eaff);
  $lnir-margin: unicode(eb00);
  $lnir-move: unicode(eb01);
  $lnir-pallet: unicode(eb02);
  $lnir-rotate: unicode(eb03);
  $lnir-scaling: unicode(eb04);
  $lnir-select: unicode(eb05);
  $lnir-size: unicode(eb06);
  $lnir-slice: unicode(eb07);
  $lnir-transform: unicode(eb08);
  $lnir-ux: unicode(eb09);
  $lnir-vector-alt: unicode(eb0a);
  $lnir-vector-pen: unicode(eb0b);
  $lnir-vector: unicode(eb0c);
  $lnir-angle-double-down: unicode(eb0d);
  $lnir-angle-double-left: unicode(eb0e);
  $lnir-angle-double-right: unicode(eb0f);
  $lnir-angle-double-up: unicode(eb10);
  $lnir-arrow-double-down: unicode(eb11);
  $lnir-arrow-double-left: unicode(eb12);
  $lnir-arrow-double-right: unicode(eb13);
  $lnir-arrow-double-top: unicode(eb14);
  $lnir-arrow-down-circle: unicode(eb15);
  $lnir-arrow-down: unicode(eb16);
  $lnir-arrow-expand-alt: unicode(eb17);
  $lnir-arrow-expand: unicode(eb18);
  $lnir-arrow-left-circle: unicode(eb19);
  $lnir-arrow-left: unicode(eb1a);
  $lnir-arrow-minimize-alt: unicode(eb1b);
  $lnir-arrow-minimize: unicode(eb1c);
  $lnir-arrow-right-circle: unicode(eb1d);
  $lnir-arrow-right: unicode(eb1e);
  $lnir-arrow-top-left: unicode(eb1f);
  $lnir-arrow-top-right: unicode(eb20);
  $lnir-arrow-up-circle: unicode(eb21);
  $lnir-arrow-up: unicode(eb22);
  $lnir-arrows-horizontal: unicode(eb23);
  $lnir-arrows-vertical: unicode(eb24);
  $lnir-axis: unicode(eb25);
  $lnir-chevron-down-circle: unicode(eb26);
  $lnir-chevron-down: unicode(eb27);
  $lnir-chevron-left-circle: unicode(eb28);
  $lnir-chevron-left: unicode(eb29);
  $lnir-chevron-right-circle: unicode(eb2a);
  $lnir-chevron-right: unicode(eb2b);
  $lnir-chevron-up-circle: unicode(eb2c);
  $lnir-chevron-up: unicode(eb2d);
  $lnir-direction-alt: unicode(eb2e);
  $lnir-direction-ltr: unicode(eb2f);
  $lnir-direction-rtl: unicode(eb30);
  $lnir-direction: unicode(eb31);
  $lnir-down-arrow-box: unicode(eb32);
  $lnir-down-left-arrow-box: unicode(eb33);
  $lnir-down-right-arrow-box: unicode(eb34);
  $lnir-enter: unicode(eb35);
  $lnir-exit-down: unicode(eb36);
  $lnir-exit-up: unicode(eb37);
  $lnir-exit: unicode(eb38);
  $lnir-left-arrow-box: unicode(eb39);
  $lnir-pointer-down: unicode(eb3a);
  $lnir-pointer-left: unicode(eb3b);
  $lnir-pointer-right: unicode(eb3c);
  $lnir-pointer-up: unicode(eb3d);
  $lnir-right-arrow-box: unicode(eb3e);
  $lnir-shift-left: unicode(eb3f);
  $lnir-shift-right: unicode(eb40);
  $lnir-top-left-arrow-box: unicode(eb41);
  $lnir-top-arrow-box: unicode(eb42);
  $lnir-top-right-arrow-box: unicode(eb43);
  $lnir-capital-letter: unicode(eb44);
  $lnir-font: unicode(eb45);
  $lnir-height: unicode(eb46);
  $lnir-indent-decrease: unicode(eb47);
  $lnir-indent-increase: unicode(eb48);
  $lnir-italic: unicode(eb49);
  $lnir-line-dashed: unicode(eb4a);
  $lnir-line-dotted: unicode(eb4b);
  $lnir-line-double: unicode(eb4c);
  $lnir-line-spacing: unicode(eb4d);
  $lnir-page-break: unicode(eb4e);
  $lnir-pagination: unicode(eb4f);
  $lnir-pilcrow: unicode(eb50);
  $lnir-redo: unicode(eb51);
  $lnir-reflect: unicode(eb52);
  $lnir-sort-alpha-asc: unicode(eb53);
  $lnir-sort-amount-asc: unicode(eb54);
  $lnir-sort-amount-dsc: unicode(eb55);
  $lnir-spellcheck: unicode(eb56);
  $lnir-text-align-justify: unicode(eb57);
  $lnir-text-align-left: unicode(eb58);
  $lnir-hammer: unicode(eb59);
  $lnir-helmet-alt: unicode(eb5a);
  $lnir-text-align-right: unicode(eb5b);
  $lnir-text-align-center: unicode(eb5c);
  $lnir-text-format-remove: unicode(eb5d);
  $lnir-text: unicode(eb5e);
  $lnir-underline: unicode(eb5f);
  $lnir-undo: unicode(eb60);
  $lnir-vertical-line: unicode(eb61);
  $lnir-atom: unicode(eb62);
  $lnir-backpack-alt: unicode(eb63);
  $lnir-backpack: unicode(eb64);
  $lnir-blackboard: unicode(eb65);
  $lnir-book-alt: unicode(eb66);
  $lnir-book: unicode(eb67);
  $lnir-books-alt: unicode(eb68);
  $lnir-books: unicode(eb69);
  $lnir-brakckets: unicode(eb6a);
  $lnir-certificate-alt-1: unicode(eb6b);
  $lnir-certificate-alt: unicode(eb6c);
  $lnir-certificate: unicode(eb6d);
  $lnir-classroom: unicode(eb6e);
  $lnir-clipboard: unicode(eb6f);
  $lnir-compass: unicode(eb70);
  $lnir-cubes: unicode(eb71);
  $lnir-eraser: unicode(eb72);
  $lnir-flask-alt-1: unicode(eb73);
  $lnir-flask-alt-2: unicode(eb74);
  $lnir-flask-alt: unicode(eb75);
  $lnir-flask: unicode(eb76);
  $lnir-graduate-alt: unicode(eb77);
  $lnir-graduate: unicode(eb78);
  $lnir-graduation: unicode(eb79);
  $lnir-letterblocks: unicode(eb7a);
  $lnir-library: unicode(eb7b);
  $lnir-medal-alt: unicode(eb7c);
  $lnir-medal: unicode(eb7d);
  $lnir-molecule-alt: unicode(eb7e);
  $lnir-molecule: unicode(eb7f);
  $lnir-pencil-alt: unicode(eb80);
  $lnir-pencil: unicode(eb81);
  $lnir-quill: unicode(eb82);
  $lnir-school-bench-alt: unicode(eb83);
  $lnir-school-bench: unicode(eb84);
  $lnir-sketchbook: unicode(eb85);
  $lnir-whiteboard-alt-1: unicode(eb86);
  $lnir-whiteboard-alt-2: unicode(eb87);
  $lnir-whiteboard-alt: unicode(eb88);
  $lnir-whiteboard: unicode(eb89);
  $lnir-world-2: unicode(eb8a);
  $lnir-world-alt: unicode(eb8b);
  $lnir-world: unicode(eb8c);
  $lnir-write: unicode(eb8d);
  $lnir-apple-alt: unicode(eb8e);
  $lnir-banana: unicode(eb8f);
  $lnir-bowl-alt: unicode(eb90);
  $lnir-bowl-alt-1: unicode(eb91);
  $lnir-bowl: unicode(eb92);
  $lnir-brocolli: unicode(eb93);
  $lnir-burger-alt-1: unicode(eb94);
  $lnir-burger-alt: unicode(eb95);
  $lnir-burger: unicode(eb96);
  $lnir-chef-hat-alt-1: unicode(eb97);
  $lnir-chef-hat-alt-2: unicode(eb98);
  $lnir-chef-hat-alt: unicode(eb99);
  $lnir-chef-hat: unicode(eb9a);
  $lnir-chicken: unicode(eb9b);
  $lnir-cocktail-alt-1: unicode(eb9c);
  $lnir-cocktail-alt: unicode(eb9d);
  $lnir-cocktail: unicode(eb9e);
  $lnir-coffee-cup-alt: unicode(eb9f);
  $lnir-coffee-cup: unicode(eba0);
  $lnir-cupcake-alt: unicode(eba1);
  $lnir-cupcake: unicode(eba2);
  $lnir-cutlery-alt-1: unicode(eba3);
  $lnir-cutlery-alt: unicode(eba4);
  $lnir-cutlery: unicode(eba5);
  $lnir-dinner-table: unicode(eba6);
  $lnir-dinner: unicode(eba7);
  $lnir-drumstick: unicode(eba8);
  $lnir-fresh-juice: unicode(eba9);
  $lnir-ice-cream-alt: unicode(ebaa);
  $lnir-ice-cream: unicode(ebab);
  $lnir-juice: unicode(ebac);
  $lnir-lolypop: unicode(ebad);
  $lnir-meat: unicode(ebae);
  $lnir-pizza-alt: unicode(ebaf);
  $lnir-pizza: unicode(ebb0);
  $lnir-plate: unicode(ebb1);
  $lnir-popcorn: unicode(ebb2);
  $lnir-restaurant: unicode(ebb3);
  $lnir-service: unicode(ebb4);
  $lnir-silverware: unicode(ebb5);
  $lnir-spatula: unicode(ebb6);
  $lnir-ambulance-alt-1: unicode(ebb7);
  $lnir-ambulance-alt: unicode(ebb8);
  $lnir-ambulance: unicode(ebb9);
  $lnir-basketball: unicode(ebba);
  $lnir-bone: unicode(ebbb);
  $lnir-brain-alt: unicode(ebbc);
  $lnir-brain-check-alt: unicode(ebbd);
  $lnir-brain-check: unicode(ebbe);
  $lnir-brain: unicode(ebbf);
  $lnir-broken-bone: unicode(ebc0);
  $lnir-capsule-alt-1: unicode(ebc1);
  $lnir-capsule-alt: unicode(ebc2);
  $lnir-capsule: unicode(ebc3);
  $lnir-capsules: unicode(ebc4);
  $lnir-cardiology: unicode(ebc5);
  $lnir-diagnosis-alt: unicode(ebc6);
  $lnir-diagnosis: unicode(ebc7);
  $lnir-dna: unicode(ebc8);
  $lnir-dropper-alt-1: unicode(ebc9);
  $lnir-dropper-alt-2: unicode(ebca);
  $lnir-dropper-alt: unicode(ebcb);
  $lnir-dropper: unicode(ebcc);
  $lnir-dumbbell: unicode(ebcd);
  $lnir-eye-test: unicode(ebce);
  $lnir-female: unicode(ebcf);
  $lnir-first-aid: unicode(ebd0);
  $lnir-gluten-free: unicode(ebd1);
  $lnir-heart-check: unicode(ebd2);
  $lnir-heart-monitor: unicode(ebd3);
  $lnir-heartrate-monitor: unicode(ebd4);
  $lnir-hospital-alt-1: unicode(ebd5);
  $lnir-hospital-alt-2: unicode(ebd6);
  $lnir-hospital-alt-3: unicode(ebd7);
  $lnir-hospital-alt-4: unicode(ebd8);
  $lnir-hospital-alt: unicode(ebd9);
  $lnir-hospital-bed-alt-1: unicode(ebda);
  $lnir-hospital-bed-alt-2: unicode(ebdb);
  $lnir-hospital-bed-alt: unicode(ebdc);
  $lnir-hospital-bed: unicode(ebdd);
  $lnir-hospital-location: unicode(ebde);
  $lnir-hospital-sign: unicode(ebdf);
  $lnir-hospital: unicode(ebe0);
  $lnir-kettlebell: unicode(ebe1);
  $lnir-lungs: unicode(ebe2);
  $lnir-male: unicode(ebe3);
  $lnir-medical-briefcase-alt: unicode(ebe4);
  $lnir-medical-briefcase: unicode(ebe5);
  $lnir-medical-protection: unicode(ebe6);
  $lnir-medical-sign: unicode(ebe7);
  $lnir-medicine-alt: unicode(ebe8);
  $lnir-medicine-bottle: unicode(ebe9);
  $lnir-medicine: unicode(ebea);
  $lnir-microscope: unicode(ebeb);
  $lnir-pulse: unicode(ebec);
  $lnir-report-laptop: unicode(ebed);
  $lnir-saline-alt-1: unicode(ebee);
  $lnir-saline: unicode(ebef);
  $lnir-skipping-rope: unicode(ebf0);
  $lnir-slim: unicode(ebf1);
  $lnir-sthethoscope: unicode(ebf2);
  $lnir-saline-alt: unicode(ebf3);
  $lnir-syringe: unicode(ebf4);
  $lnir-test-tube-alt: unicode(ebf5);
  $lnir-test-tube: unicode(ebf6);
  $lnir-thermometer-alt: unicode(ebf7);
  $lnir-thermometer: unicode(ebf8);
  $lnir-tooth: unicode(ebf9);
  $lnir-walker: unicode(ebfa);
  $lnir-weight-machine: unicode(ebfb);
  $lnir-weight: unicode(ebfc);
  $lnir-wheelchair: unicode(ebfd);
  $lnir-yoga-mat: unicode(ebfe);
  $lnir-achievement: unicode(ebff);
  $lnir-analytics-alt-1: unicode(ec00);
  $lnir-agenda: unicode(ec01);
  $lnir-apartment-alt: unicode(ec02);
  $lnir-apartment: unicode(ec03);
  $lnir-briefcase-alt: unicode(ec04);
  $lnir-briefcase: unicode(ec05);
  $lnir-cart-alt: unicode(ec06);
  $lnir-cart-down: unicode(ec07);
  $lnir-cart-empty: unicode(ec08);
  $lnir-cart-full: unicode(ec09);
  $lnir-cart: unicode(ec0a);
  $lnir-consulting: unicode(ec0b);
  $lnir-customer: unicode(ec0c);
  $lnir-delivery: unicode(ec0d);
  $lnir-desk: unicode(ec0e);
  $lnir-global: unicode(ec0f);
  $lnir-grow: unicode(ec10);
  $lnir-handshake: unicode(ec11);
  $lnir-hierchy-alt: unicode(ec12);
  $lnir-hierchy: unicode(ec13);
  $lnir-invest-monitor: unicode(ec14);
  $lnir-investment: unicode(ec15);
  $lnir-license: unicode(ec16);
  $lnir-notepad: unicode(ec17);
  $lnir-offer: unicode(ec18);
  $lnir-office-plan: unicode(ec19);
  $lnir-pie-chart-alt: unicode(ec1a);
  $lnir-paperclip: unicode(ec1b);
  $lnir-pie-chart-dollar: unicode(ec1c);
  $lnir-pie-chart: unicode(ec1d);
  $lnir-pie-report: unicode(ec1e);
  $lnir-pin-alt: unicode(ec1f);
  $lnir-pin: unicode(ec20);
  $lnir-quotation: unicode(ec21);
  $lnir-revenue: unicode(ec22);
  $lnir-sales-report: unicode(ec23);
  $lnir-stamp: unicode(ec24);
  $lnir-stats-down: unicode(ec25);
  $lnir-stats-up: unicode(ec26);
  $lnir-swatches: unicode(ec27);
  $lnir-table: unicode(ec28);
  $lnir-tablet-alt: unicode(ec29);
  $lnir-tag: unicode(ec2a);
  $lnir-target-achievement: unicode(ec2b);
  $lnir-target-alt-1: unicode(ec2c);
  $lnir-target-alt: unicode(ec2d);
  $lnir-target-remove: unicode(ec2e);
  $lnir-target-revenue: unicode(ec2f);
  $lnir-target: unicode(ec30);
  $lnir-tie: unicode(ec31);
  $lnir-analytics: unicode(ec32);
  $lnir-bar-chart: unicode(ec33);
  $lnir-bar-check: unicode(ec34);
  $lnir-billboard-alt: unicode(ec35);
  $lnir-billboard: unicode(ec36);
  $lnir-graph-alt-1: unicode(ec37);
  $lnir-graph-alt-2: unicode(ec38);
  $lnir-graph-alt-3: unicode(ec39);
  $lnir-graph-alt-4: unicode(ec3a);
  $lnir-graph-alt: unicode(ec3b);
  $lnir-graph-decrease: unicode(ec3c);
  $lnir-graph-increase: unicode(ec3d);
  $lnir-graph: unicode(ec3e);
  $lnir-keyword-research: unicode(ec3f);
  $lnir-network-alt: unicode(ec40);
  $lnir-network: unicode(ec41);
  $lnir-pyramid-decrease: unicode(ec42);
  $lnir-pyramid-increase: unicode(ec43);
  $lnir-pyramid: unicode(ec44);
  $lnir-rook: unicode(ec45);
  $lnir-seo: unicode(ec46);
  $lnir-strategy: unicode(ec47);
  $lnir-target-audience-alt: unicode(ec48);
  $lnir-target-customer: unicode(ec49);
  $lnir-website-rank: unicode(ec4a);
  $lnir-barrier: unicode(ec4b);
  $lnir-bolt-alt: unicode(ec4c);
  $lnir-bolt: unicode(ec4d);
  $lnir-checkbox: unicode(ec4e);
  $lnir-checkmark-circle: unicode(ec4f);
  $lnir-checkmark: unicode(ec50);
  $lnir-circle-minus: unicode(ec51);
  $lnir-circle-plus: unicode(ec52);
  $lnir-close: unicode(ec53);
  $lnir-cross-circle: unicode(ec54);
  $lnir-electricity: unicode(ec55);
  $lnir-eye-alt: unicode(ec56);
  $lnir-eye: unicode(ec57);
  $lnir-find: unicode(ec58);
  $lnir-flag-alt: unicode(ec59);
  $lnir-flag: unicode(ec5a);
  $lnir-funnel-alt-1: unicode(ec5b);
  $lnir-funnel-alt: unicode(ec5c);
  $lnir-funnel: unicode(ec5d);
  $lnir-hand: unicode(ec5e);
  $lnir-heart-filled: unicode(ec5f);
  $lnir-heart-plus: unicode(ec60);
  $lnir-heart: unicode(ec61);
  $lnir-help: unicode(ec62);
  $lnir-hotel-sign: unicode(ec63);
  $lnir-idea-alt: unicode(ec64);
  $lnir-identity: unicode(ec65);
  $lnir-infinite: unicode(ec66);
  $lnir-information: unicode(ec67);
  $lnir-invention: unicode(ec68);
  $lnir-key-alt-1: unicode(ec69);
  $lnir-key-alt-2: unicode(ec6a);
  $lnir-key-alt-3: unicode(ec6b);
  $lnir-key-alt: unicode(ec6c);
  $lnir-key: unicode(ec6d);
  $lnir-life-ring: unicode(ec6e);
  $lnir-list-alt-1: unicode(ec6f);
  $lnir-list-alt: unicode(ec70);
  $lnir-list: unicode(ec71);
  $lnir-lock-alt-1: unicode(ec72);
  $lnir-lock-alt-2: unicode(ec73);
  $lnir-lock-alt: unicode(ec74);
  $lnir-lock: unicode(ec75);
  $lnir-minus: unicode(ec76);
  $lnir-more-alt-1: unicode(ec77);
  $lnir-more-alt: unicode(ec78);
  $lnir-more: unicode(ec79);
  $lnir-music-note: unicode(ec7a);
  $lnir-music: unicode(ec7b);
  $lnir-notification: unicode(ec7c);
  $lnir-passport-alt: unicode(ec7d);
  $lnir-passport: unicode(ec7e);
  $lnir-playlist-alt: unicode(ec7f);
  $lnir-playlist: unicode(ec80);
  $lnir-plus: unicode(ec81);
  $lnir-pointer: unicode(ec82);
  $lnir-power-switch: unicode(ec83);
  $lnir-protection: unicode(ec84);
  $lnir-question-circle: unicode(ec85);
  $lnir-radio-button: unicode(ec86);
  $lnir-record: unicode(ec87);
  $lnir-round-box-check: unicode(ec88);
  $lnir-round-box-cross: unicode(ec89);
  $lnir-round-box-minus: unicode(ec8a);
  $lnir-round-box-plus: unicode(ec8b);
  $lnir-shield: unicode(ec8c);
  $lnir-sort: unicode(ec8d);
  $lnir-switch: unicode(ec8e);
  $lnir-teabag: unicode(ec8f);
  $lnir-thumbs-down-alt: unicode(ec90);
  $lnir-thumbs-down: unicode(ec91);
  $lnir-thumbs-up-alt: unicode(ec92);
  $lnir-thumbs-up: unicode(ec93);
  $lnir-thunderbolt: unicode(ec94);
  $lnir-ticket-alt-1: unicode(ec95);
  $lnir-ticket-alt-2: unicode(ec96);
  $lnir-ticket-alt-3: unicode(ec97);
  $lnir-ticket-alt: unicode(ec98);
  $lnir-ticket: unicode(ec99);
  $lnir-unlock: unicode(ec9a);
  $lnir-warning: unicode(ec9b);
  $lnir-backward-alt: unicode(ec9c);
  $lnir-backward: unicode(ec9d);
  $lnir-expand-alt-1: unicode(ec9e);
  $lnir-fast-forward: unicode(ec9f);
  $lnir-film: unicode(eca0);
  $lnir-forward: unicode(eca1);
  $lnir-frame-expand: unicode(eca2);
  $lnir-full-screen: unicode(eca3);
  $lnir-gallery: unicode(eca4);
  $lnir-next: unicode(eca5);
  $lnir-pause: unicode(eca6);
  $lnir-play: unicode(eca7);
  $lnir-previous: unicode(eca8);
  $lnir-repeat-one: unicode(eca9);
  $lnir-shuffle: unicode(ecaa);
  $lnir-slideshow: unicode(ecab);
  $lnir-stop: unicode(ecac);
  $lnir-video-alt-1: unicode(ecad);
  $lnir-video-alt: unicode(ecae);
  $lnir-video-camera-alt-1: unicode(ecaf);
  $lnir-video-camera-alt-2: unicode(ecb0);
  $lnir-video-camera-alt: unicode(ecb1);
  $lnir-video-cut: unicode(ecb2);
  $lnir-video: unicode(ecb3);
  $lnir-volume-high: unicode(ecb4);
  $lnir-volume-low: unicode(ecb5);
  $lnir-volume-medium: unicode(ecb6);
  $lnir-volume-mute: unicode(ecb7);
  $lnir-volume: unicode(ecb8);
  $lnir-bank: unicode(ecb9);
  $lnir-calculator-alt: unicode(ecba);
  $lnir-calculator: unicode(ecbb);
  $lnir-coin: unicode(ecbc);
  $lnir-coins: unicode(ecbd);
  $lnir-credit-card: unicode(ecbe);
  $lnir-credit-cards: unicode(ecbf);
  $lnir-diamond-alt: unicode(ecc0);
  $lnir-diamond: unicode(ecc1);
  $lnir-dollar-card: unicode(ecc2);
  $lnir-dollar-down: unicode(ecc3);
  $lnir-dollar-up: unicode(ecc4);
  $lnir-dollar: unicode(ecc5);
  $lnir-euro-card: unicode(ecc6);
  $lnir-euro-coin: unicode(ecc7);
  $lnir-euro-down: unicode(ecc8);
  $lnir-euro-up: unicode(ecc9);
  $lnir-euro: unicode(ecca);
  $lnir-gold-bar: unicode(eccb);
  $lnir-mobile-coin-taka: unicode(eccc);
  $lnir-mobile-coin: unicode(eccd);
  $lnir-money-bag: unicode(ecce);
  $lnir-money-location: unicode(eccf);
  $lnir-money-protection: unicode(ecd0);
  $lnir-pound-card: unicode(ecd1);
  $lnir-pound-coin: unicode(ecd2);
  $lnir-pound-down: unicode(ecd3);
  $lnir-pound-up: unicode(ecd4);
  $lnir-pound: unicode(ecd5);
  $lnir-rupee-card: unicode(ecd6);
  $lnir-rupee-coin: unicode(ecd7);
  $lnir-text-format: unicode(ecd8);
  $lnir-rupee-down: unicode(ecd9);
  $lnir-bold: unicode(ecda);
  $lnir-taka-card: unicode(ecdb);
  $lnir-taka-coin: unicode(ecdc);
  $lnir-strikethrough: unicode(ecdd);
  $lnir-taka-down: unicode(ecde);
  $lnir-rupee: unicode(ecdf);
  $lnir-taka-up: unicode(ece0);
  $lnir-taka: unicode(ece1);
  $lnir-rupee-up: unicode(ece2);
  $lnir-wallet-alt-1: unicode(ece3);
  $lnir-wallet: unicode(ece4);
  $lnir-yen-card: unicode(ece5);
  $lnir-yen-coin: unicode(ece6);
  $lnir-yen-up: unicode(ece7);
  $lnir-bug-alt: unicode(ece8);
  $lnir-yen-down: unicode(ece9);
  $lnir-yen: unicode(ecea);
  $lnir-cactus-alt: unicode(eceb);
  $lnir-flower: unicode(ecec);
  $lnir-bug: unicode(eced);
  $lnir-cactus: unicode(ecee);
  $lnir-island: unicode(ecef);
  $lnir-leaf-alt: unicode(ecf0);
  $lnir-leaf: unicode(ecf1);
  $lnir-leaves: unicode(ecf2);
  $lnir-mashroom: unicode(ecf3);
  $lnir-mountain: unicode(ecf4);
  $lnir-mushroom-alt: unicode(ecf5);
  $lnir-mushrooms: unicode(ecf6);
  $lnir-plant-pot: unicode(ecf7);
  $lnir-plant: unicode(ecf8);
  $lnir-sprout-alt: unicode(ecf9);
  $lnir-sprout: unicode(ecfa);
  $lnir-tree-alt-1: unicode(ecfb);
  $lnir-tree-alt: unicode(ecfc);
  $lnir-tree: unicode(ecfd);
  $lnir-trees-alt-1: unicode(ecfe);
  $lnir-trees-alt: unicode(ecff);
  $lnir-trees: unicode(ed00);
  $lnir-baloon-alt-1: unicode(ed01);
  $lnir-baloon-alt: unicode(ed02);
  $lnir-baloon: unicode(ed03);
  $lnir-birthday-hat: unicode(ed04);
  $lnir-cake: unicode(ed05);
  $lnir-leaf-alt-1: unicode(ed06);
  $lnir-candy-cane: unicode(ed07);
  $lnir-tornado: unicode(ed08);
  $lnir-candy: unicode(ed09);
  $lnir-confetti: unicode(ed0a);
  $lnir-firecracker: unicode(ed0b);
  $lnir-fireworks: unicode(ed0c);
  $lnir-flags: unicode(ed0d);
  $lnir-mask: unicode(ed0e);
  $lnir-spray: unicode(ed0f);
  $lnir-wand: unicode(ed10);
  $lnir-cotton-bud: unicode(ed11);
  $lnir-crown-alt-1: unicode(ed12);
  $lnir-crown-alt: unicode(ed13);
  $lnir-crown-alt-2: unicode(ed14);
  $lnir-crown: unicode(ed15);
  $lnir-cup-alt: unicode(ed16);
  $lnir-flower-alt: unicode(ed17);
  $lnir-gift-alt-1: unicode(ed18);
  $lnir-gift-alt: unicode(ed19);
  $lnir-gift-bag: unicode(ed1a);
  $lnir-gift: unicode(ed1b);
  $lnir-glasses: unicode(ed1c);
  $lnir-home: unicode(ed1d);
  $lnir-ladies-tshirt: unicode(ed1e);
  $lnir-sunglass: unicode(ed1f);
  $lnir-thought: unicode(ed20);
  $lnir-tshirt-alt-1: unicode(ed21);
  $lnir-tshirt-alt: unicode(ed22);
  $lnir-tshirt: unicode(ed23);
  $lnir-beach-chair: unicode(ed24);
  $lnir-beach: unicode(ed25);
  $lnir-beachball: unicode(ed26);
  $lnir-cable-car: unicode(ed27);
  $lnir-flight-search: unicode(ed28);
  $lnir-hot-air-baloon: unicode(ed29);
  $lnir-pyramids: unicode(ed2a);
  $lnir-suitcase-alt: unicode(ed2b);
  $lnir-suitcase: unicode(ed2c);
  $lnir-surf-board: unicode(ed2d);
  $lnir-tent-alt: unicode(ed2e);
  $lnir-tent: unicode(ed2f);
  $lnir-travel: unicode(ed30);
  $lnir-air-flow: unicode(ed31);
  $lnir-air: unicode(ed32);
  $lnir-cloud-alt-1: unicode(ed33);
  $lnir-cloud-alt-2: unicode(ed34);
  $lnir-cloud-alt: unicode(ed35);
  $lnir-cloud-moon: unicode(ed36);
  $lnir-cloud-sun: unicode(ed37);
  $lnir-cloud: unicode(ed38);
  $lnir-cloudy-sun: unicode(ed39);
  $lnir-drop-alt: unicode(ed3a);
  $lnir-drop: unicode(ed3b);
  $lnir-farenheit: unicode(ed3c);
  $lnir-moon-star: unicode(ed3d);
  $lnir-moon: unicode(ed3e);
  $lnir-night-thunder: unicode(ed3f);
  $lnir-night-wind: unicode(ed40);
  $lnir-night: unicode(ed41);
  $lnir-rain: unicode(ed42);
  $lnir-snowfall: unicode(ed43);
  $lnir-sun-rain: unicode(ed44);
  $lnir-sun: unicode(ed45);
  $lnir-thunder-alt: unicode(ed46);
  $lnir-thunder: unicode(ed47);
  $lnir-umbrella-alt: unicode(ed48);
  $lnir-umbrella-rain-alt: unicode(ed49);
  $lnir-umbrella-rain: unicode(ed4a);
  $lnir-umbrella: unicode(ed4b);
  $lnir-water-drops: unicode(ed4c);
  $lnir-wind-cloud: unicode(ed4d);
  $lnir-wind-sun: unicode(ed4e);
  $lnir-hourglass: unicode(ed4f);
  $lnir-spiner-solid: unicode(ed50);
  $lnir-spinner-1: unicode(ed51);
  $lnir-spinner-10: unicode(ed52);
  $lnir-cup: unicode(ed53);
  $lnir-spinner-11: unicode(ed54);
  $lnir-spinner-5: unicode(ed55);
  $lnir-spinner-6: unicode(ed56);
  $lnir-spinner-2: unicode(ed57);
  $lnir-spinner-3: unicode(ed58);
  $lnir-spinner-7: unicode(ed59);
  $lnir-spinner-8: unicode(ed5a);
  $lnir-spinner-9: unicode(ed5b);
  $lnir-spinner-arrow: unicode(ed5c);
  $lnir-spinner: unicode(ed5d);
  $lnir-3d: unicode(ed5e);
  $lnir-add-files: unicode(ed5f);
  $lnir-ai: unicode(ed60);
  $lnir-alarm-2: unicode(ed61);
  $lnir-alarm-3: unicode(ed62);
  $lnir-alarm-4: unicode(ed63);
  $lnir-alarm-clock-alt: unicode(ed64);
  $lnir-alarm-clock: unicode(ed65);
  $lnir-alarm: unicode(ed66);
  $lnir-candle-alt: unicode(ed67);
  $lnir-spinner-4: unicode(ed68);
  $lnir-archive: unicode(ed69);
  $lnir-backspace: unicode(ed6a);
  $lnir-battery: unicode(ed6b);
  $lnir-block-user: unicode(ed6c);
  $lnir-bluetooth: unicode(ed6d);
  $lnir-bookmark-alt: unicode(ed6e);
  $lnir-bookmark: unicode(ed6f);
  $lnir-brightness: unicode(ed70);
  $lnir-bulb-alt: unicode(ed71);
  $lnir-bulb: unicode(ed72);
  $lnir-calendar: unicode(ed73);
  $lnir-calender-alt-1: unicode(ed74);
  $lnir-calender-alt-2: unicode(ed75);
  $lnir-calender-alt-3: unicode(ed76);
  $lnir-calender-alt-4: unicode(ed77);
  $lnir-calender-alt: unicode(ed78);
  $lnir-camera-alt-1: unicode(ed79);
  $lnir-camera-alt: unicode(ed7a);
  $lnir-camera: unicode(ed7b);
  $lnir-change: unicode(ed7c);
  $lnir-charging: unicode(ed7d);
  $lnir-cloud-check-alt: unicode(ed7e);
  $lnir-cloud-check: unicode(ed7f);
  $lnir-cloud-computing-alt: unicode(ed80);
  $lnir-cloud-computing: unicode(ed81);
  $lnir-cloud-download-alt: unicode(ed82);
  $lnir-cloud-download: unicode(ed83);
  $lnir-cloud-network-2: unicode(ed84);
  $lnir-cloud-network: unicode(ed85);
  $lnir-cloud-search-alt-1: unicode(ed86);
  $lnir-cloud-search-alt: unicode(ed87);
  $lnir-cloud-search: unicode(ed88);
  $lnir-cloud-sync-alt: unicode(ed89);
  $lnir-cloud-sync: unicode(ed8a);
  $lnir-cloud-upload-alt: unicode(ed8b);
  $lnir-cloud-upload: unicode(ed8c);
  $lnir-code-alt: unicode(ed8d);
  $lnir-code: unicode(ed8e);
  $lnir-cog: unicode(ed8f);
  $lnir-cogs: unicode(ed90);
  $lnir-control-panel: unicode(ed91);
  $lnir-copy: unicode(ed92);
  $lnir-cut: unicode(ed93);
  $lnir-dashboard: unicode(ed94);
  $lnir-database: unicode(ed95);
  $lnir-display-alt: unicode(ed96);
  $lnir-display: unicode(ed97);
  $lnir-domain: unicode(ed98);
  $lnir-download: unicode(ed99);
  $lnir-empty-battery: unicode(ed9a);
  $lnir-empty-file: unicode(ed9b);
  $lnir-file-download: unicode(ed9c);
  $lnir-file-lock: unicode(ed9d);
  $lnir-file-name: unicode(ed9e);
  $lnir-file-protection: unicode(ed9f);
  $lnir-file-upload: unicode(eda0);
  $lnir-files-alt: unicode(eda1);
  $lnir-files: unicode(eda2);
  $lnir-folder-alt-1: unicode(eda3);
  $lnir-folder-alt: unicode(eda4);
  $lnir-game: unicode(eda5);
  $lnir-folder: unicode(eda6);
  $lnir-half-battery: unicode(eda7);
  $lnir-headphone: unicode(eda8);
  $lnir-image: unicode(eda9);
  $lnir-keyboard: unicode(edaa);
  $lnir-laptop-alt-keyboard: unicode(edab);
  $lnir-laptop-alt-switch: unicode(edac);
  $lnir-laptop-alt: unicode(edad);
  $lnir-laptop-phone: unicode(edae);
  $lnir-laptop: unicode(edaf);
  $lnir-link-alt-1: unicode(edb0);
  $lnir-link-alt: unicode(edb1);
  $lnir-link: unicode(edb2);
  $lnir-low-battery: unicode(edb3);
  $lnir-magnet: unicode(edb4);
  $lnir-magnifier: unicode(edb5);
  $lnir-map-marker: unicode(edb6);
  $lnir-map: unicode(edb7);
  $lnir-menu-alt-1: unicode(edb8);
  $lnir-menu-alt-2: unicode(edb9);
  $lnir-menu-alt-3: unicode(edba);
  $lnir-menu-alt-4: unicode(edbb);
  $lnir-menu-alt-5: unicode(edbc);
  $lnir-menu-alt: unicode(edbd);
  $lnir-menu-circle: unicode(edbe);
  $lnir-menu: unicode(edbf);
  $lnir-mic: unicode(edc0);
  $lnir-microphone-alt-1: unicode(edc1);
  $lnir-microphone-alt: unicode(edc2);
  $lnir-microphone: unicode(edc3);
  $lnir-mobile-alt-1: unicode(edc4);
  $lnir-mobile-alt-2: unicode(edc5);
  $lnir-harddrive: unicode(edc6);
  $lnir-mobile-alt-button: unicode(edc7);
  $lnir-mobile: unicode(edc8);
  $lnir-headphone-alt: unicode(edc9);
  $lnir-full-battery: unicode(edca);
  $lnir-mouse: unicode(edcb);
  $lnir-no-charge-battery: unicode(edcc);
  $lnir-package: unicode(edcd);
  $lnir-plug: unicode(edce);
  $lnir-presentation: unicode(edcf);
  $lnir-printer: unicode(edd0);
  $lnir-ps: unicode(edd1);
  $lnir-recycle-alt: unicode(edd2);
  $lnir-recycle: unicode(edd3);
  $lnir-reload-alt-box: unicode(edd4);
  $lnir-reload-alt: unicode(edd5);
  $lnir-reload: unicode(edd6);
  $lnir-remove-file: unicode(edd7);
  $lnir-rocket: unicode(edd8);
  $lnir-rss-feed: unicode(edd9);
  $lnir-save-alt: unicode(edda);
  $lnir-save: unicode(eddb);
  $lnir-scan: unicode(eddc);
  $lnir-scroll-down: unicode(eddd);
  $lnir-search-alt: unicode(edde);
  $lnir-search: unicode(eddf);
  $lnir-share-alt: unicode(ede0);
  $lnir-share: unicode(ede1);
  $lnir-shopping-basket: unicode(ede2);
  $lnir-shortcode: unicode(ede3);
  $lnir-signal: unicode(ede4);
  $lnir-star-empty: unicode(ede5);
  $lnir-star-filled: unicode(ede6);
  $lnir-star-half: unicode(ede7);
  $lnir-star: unicode(ede8);
  $lnir-tab: unicode(ede9);
  $lnir-tap: unicode(edea);
  $lnir-thumbnail: unicode(edeb);
  $lnir-timer: unicode(edec);
  $lnir-trash-can-alt-1: unicode(eded);
  $lnir-trash-can-alt: unicode(edee);
  $lnir-trash-can: unicode(edef);
  $lnir-trash: unicode(edf0);
  $lnir-unlink: unicode(edf1);
  $lnir-upload: unicode(edf2);
  $lnir-user-alt-1: unicode(edf3);
  $lnir-user-alt-2: unicode(edf4);
  $lnir-user-alt: unicode(edf5);
  $lnir-user: unicode(edf6);
  $lnir-users-2: unicode(edf7);
  $lnir-users: unicode(edf8);
  $lnir-website-alt: unicode(edf9);
  $lnir-website: unicode(edfa);
  $lnir-zip: unicode(edfb);
  $lnir-ban: unicode(edfc);
  $lnir-candle: unicode(edfd);
  $lnir-appointment: unicode(edfe);
  $lnir-zoom-in: unicode(edff);
  $lnir-cool-1: unicode(ee00);
  $lnir-cool-3: unicode(ee01);
  $lnir-cool-kiss: unicode(ee02);
  $lnir-cry: unicode(ee03);
  $lnir-cute: unicode(ee04);
  $lnir-dead: unicode(ee05);
  $lnir-bored: unicode(ee06);
  $lnir-emoji-friendly: unicode(ee07);
  $lnir-emoji-happy: unicode(ee08);
  $lnir-emoji-sad: unicode(ee09);
  $lnir-emoji-cool: unicode(ee0a);
  $lnir-emoji-speechless: unicode(ee0b);
  $lnir-emoji-suspect: unicode(ee0c);
  $lnir-emoji-tounge: unicode(ee0d);
  $lnir-emoji-smile: unicode(ee0e);
  $lnir-happy-2: unicode(ee0f);
  $lnir-happy-smile: unicode(ee10);
  $lnir-kiss-2: unicode(ee11);
  $lnir-kiss: unicode(ee12);
  $lnir-angry: unicode(ee13);
  $lnir-loved-surprise: unicode(ee14);
  $lnir-zoom-out: unicode(ee15);
  $lnir-piss: unicode(ee16);
  $lnir-sick-1: unicode(ee17);
  $lnir-sick: unicode(ee18);
  $lnir-mute: unicode(ee19);
  $lnir-super-cool: unicode(ee1a);
  $lnir-love: unicode(ee1b);
  $lnir-wink: unicode(ee1c);
  $lnir-wow: unicode(ee1d);
  $lnir-yawn: unicode(ee1e);
  $lnir-super-happy: unicode(ee1f);
  $lnir-happy-1: unicode(ee20);
  $lnir-sleep: unicode(ee21);