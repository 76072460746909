@import "../variable";

.navSideContain{
    width:280px;
    position: fixed;
    left: 0;
    top:0;
    bottom:0;
    z-index:9;
    overflow: auto;
}

.midContent{
    padding:30px 40px 20px 40px;
    width: calc(100% - 400px);
    // justify-content:flex-start;
    .innerContent{
    }  
    // margin-right:400px;
    // height:100vh;
    // overflow: auto;
}

.rightSideList{
    width:400px;
    position: sticky;
    // left: 100%;
    bottom:0;
    // overflow: auto;
    min-height:100vh;
    z-index:99;
}

.pageContain{
    margin-left:280px;
    // align-items: flex-end;
    height:100%
}

.badgeList{
    .badge{
        border-radius:3px;
        height:28px;
        padding:0 13px;
        line-height:28px;
        background-color:#f0f1f6;
        border:0 none;
        margin-left:5px;
        cursor: pointer;
        margin-bottom:5px;
        &.badge-active,&:hover{
            background-color:$primary;
            color:$bright;
        }
    }
}


// player groups
.playGroup{
    border-bottom:1px solid #e5e5e5;
    padding:15px 0;
    &.active{
        border-color:#fff;
        background-color:#f0f1f6;
        .topView{
            .action{
                a{
                    color:$primary;
                }
            }
        }
        .info .musicCreator{
            color:$primary;
            text-decoration: underline;
        }
    }
    .count{
        width:45px;
        padding-left:20px;
        font-size:14px;
    }
    span{
        color:$lightGray;
    }
    .info{
        width:320px;
        padding-right:35px;
        position: relative;
        .img{
            figure{
                width:42px;
                height:42px;
                background-color:#fff;
                margin-bottom:0;
                border-radius:6px;
                text-align: center;
                line-height:42px;
            }
            padding-right:15px;
        }
        .musicTitle{
            font-size:16px;
            color:$dark;
            max-width: 220px;
        }
        .musicCreator{
            font-size:13px;
            color:$lightGray;
            max-width: 220px;
        }
    }
    // .songType{
    //     width: 120px;
    // }
    // .createDate{
    //     width: 120px;
    // }
    // .genre{
    //     width: 200px;
    // }
    // .version{
    //     width: 120px;
    // }
    .topView{
        font-size:12px;
        padding:0 8px 0 0px;
    }
    .action{
        order:4;
        span{
            font-size:14px;
            padding: 12px;
            color:$lightGray;
            &:hover{
                color:$primary;
            }
        }
    }
    .expandPlaylist{
        padding:32px 8px 0 0;
        .song{
            font-size:14px;
            padding:13px 0 13px 60px;
            &.active{
                .titleName{
                    text-decoration: underline;
                }
                .play-icon{
                    &::before{
                        border:0 none;
                        background-color:$primary;
                        width:3px;
                        height:12px;
                    }
                    &::after{
                        border:0 none;
                        background-color:$primary;
                        width:3px;
                        height:12px;
                        left:5px;
                        content:'';
                        position: absolute;
                    }
                }
            }
            &:hover{
                cursor: pointer;
                .titleName{
                    color:$primary;
                }
            }
            .downloadCheck{
                i{
                    margin-left:10px;
                    color:$lightGray;
                }
                i.active{
                    color:$primary;
                }
            }
            .ico{
                width:11px;
                height:12px;
                position: relative;
            }
            .play-icon{
                &::before{
                    border-width: 6px 0 6px 11px;
                }
            }
            .pause-icon{
                &::before{
                    width:3px;
                    background-color:#f04d25;
                    height:12px;
                    content:'';
                    position: absolute;
                    left:0px;
                }
                &::after{
                    position: absolute;
                    width:3px;
                    background-color:#f04d25;
                    height:12px;
                    content:'';
                    right:0px;
                }
            }
            .titleName{
                margin-left:30px;
            }
        }
    }
    .infoMid{
        flex:1;
        order:3;
        > div{
            flex:1;
            padding-right:15px;
        }
    }
}



@media only screen and (max-width: 1700px) {
    .rightSideList{
        width:320px;
    }
    .pageContain{
        // margin-right:320px;
        margin-left:220px;
    }
    .midContent{
        padding-left:20px;
        padding-right:20px;
        width: calc(100% - 320px);
    }    
}


@media only screen and (max-width: 1400px) {
    .pageContain{
        flex-direction: column;
        .midContent{
            width:100%;
        }
        .rightSideList{
            width:100%;
            position: static;
            min-height: auto    ;
        }
    }
}

@media only screen and (max-width: 1100px) {
    .playGroup{
        .infoMid{
            order: 4 !important;
    width: 100%;
    padding: 10px 20px 0px 20px;
    border-top: 1px solid #ddd;
    margin-top: 20px;
    display:none !important;
        }
        .expandPlaylist{
            padding-top:15px;
            border-top: 1px solid #ddd;
            margin-top: 15px;        
        }
        .info{
            flex: 1;
    padding-right: 10px;    width: auto;
        }
        .expandPlaylist .song{
            padding: 15px 0 15px 15px !important;
        }
        .expandPlaylist{
            padding-top:10px !important;
        }
    }
    .playGroup.active{
        .infoMid{
            display:flex !important;
            flex: auto;
        }
    }


}

@media only screen and (max-width: 991px) {
    .themeMode .custom-control-label{
        padding: 0;
        text-indent: 100px;
        width: 60px;
        overflow: hidden;
        height: 32px;
    }
    .themeMode .custom-control.custom-switch{
        padding-right:0px !important;
    }
    .themeMode .custom-control.custom-switch .custom-control-label::before{
        padding-left:0;
        right:0px !important;
        text-indent: 0px;
    }
    .themeMode .custom-control.custom-switch .custom-control-label::after{
        right:31px !important;
        text-indent: 0px;
    }

    .themeMode .custom-control.custom-switch .custom-control-input:checked ~ .custom-control-label::after{
        right:15px !important;            
    }

    .cartLink i.lnir-package{
        margin-right:0px !important;
    }

    .searchBox.input-group .form-control{
        min-width: 200px !important;
        font-size: 14px !important;
    }

}
@media only screen and (max-width: 767px) {
    .pageContain{
        margin-left:0px;
    }
    .navSideContain{
        left:-295px;
        width:295px !important;
        z-index:9;
        background-color:#ffffff;
    }


    .navSideContain.active{
        left: 0;
    }
    

    .searchBox{
        padding: 10px;
        background-color: #eeeeee;
        border-radius: 6px;
        margin-top: 11px;
        order:3 !important;
        width: 100% !important;
        .form-control{
            background-color:#eeeeee;
        }
    }
    .optionSide {
        order: auto !important;
    }

    .header-logo{
        img{
            height:60px
        }
    }
}



@media only screen and (max-width: 576px) {

    .playGroup .count{
        display: none;
    }

    .playGroup .info .musicCreator,
    .playGroup .info .musicTitle{
        max-width: 130px;
    }


}

.trackList .count{
    .pause-icon{
        width: 11px;
        height: 12px;
        position: relative;
        display: block;
        &::before{
            width:3px;
            background-color:#f04d25;
            height:12px;
            content:'';
            position: absolute;
            left:0px;
        }
        &::after{
            position: absolute;
            width:3px;
            background-color:#f04d25;
            height:12px;
            content:'';
            right:0px;
        }
    }
}


body.dark-theme{
    .rightSideList{
        .RightBar{
            background-color:#2a2a2a;
        }
    }
    .playGroup{
        border-bottom-color:#3e3e3e;
    }
    .badgeList .badge{
        background-color:#333333;
    }
    .playGroup .info .musicTitle{
        color:#ffffff;
    }
    .page-item .page-link{
        color: #ffffff;
        background-color: #181818;
        border-color: #363636;
        &:hover{
            background-color: $primary;
        }
    }

    .slick-arrow.slick-disabled{
        background-color: #141414;
        border-color: #3e3e3e;
        &::before{
            color:#ffffff;
        }
    }
    .genresList ul li a,.albumGroup h6 a{
        color:#ffffff;
        &:hover{
            color:$primary;
        }
    }
    .searchBox.input-group .form-control{
        background-color: transparent;
    }

    .card{
        border:1px solid #4c4c4c !important;
    }

    .form-control{
        border: 1px solid #919191;
        background-color: transparent;
        color: #fff;
    }

    .table{
        color: #ffffff;
    }
    .table tbody tr:hover td {
        background-color: #1c1c1c;
        color:#ffffff;
    }
    .table th, .table td{
        border-top:1px solid #4e4e4e;
    }
    .linkAction {
        color: #1f79ff;
    }
    .recentTicket{
        border-bottom:1px solid #4e4e4e;
    }

    .ticket-summary .ticket-number.tt a{
        color: #106eff;
    }
    .accordion .card {
        background: transparent;
        border-top: 0 !important;
        border-left: 0 !important;
        border-right: 0 !important;
    }
    .text-muted {
        color: #8d8d8d !important;
    }

    .btn-light{
        border-color: $primary;
    }

    .page-item.active .page-link {
        background-color: #ffa800;
        border-color: #ffa800;
    }

    .payment-summary.bg-light{
        background-color: #333333 !important;
    }
    .btn-outline-secondary{
        color: #ffffff;
        border-color: #ffffff;
    }
    .border-dark{
        border-color: #FFFFFF !important;
    }
    .badgeList{
        .badge.badge-active{
            background-color: #ffa800;
        }
    } 
}

.switch-lg{
    .custom-switch .custom-control-label::before{
        width: 3.75rem;
        border-radius: 1.5rem;
        left: -4.25rem;
    }
    .custom-control-label::before{
            height: 2rem;
    }
    .custom-switch .custom-control-label::after{
        width: calc(2rem - 4px);
        height: calc(2rem - 4px);
        border-radius: 1.5rem;
        left: calc(-4.25rem + 2px);
    }
    .custom-switch .custom-control-input:checked ~ .custom-control-label::after{
        transform: translateX(1.75rem);
    }
    .custom-control-input:checked ~ .custom-control-label::before{
        background-color: $primary;
        border-color: $primary;
    }


    .custom-switch {
        padding-left: 4.25rem;
        }

        .custom-control-label{
            line-height: 40px;
            font-size: 17px;
        }
    
}