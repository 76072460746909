@import "../../variable";

.content-footer{
    background-color:#f0f1f6;
    font-family: 'cerebri_sansregular';
    font-size:13px;
    margin-bottom:-101px;
    a{
        color:#333333;
        span{
            text-decoration: underline;
        }
        &:hover{
                color:$primary;
                text-decoration: none;
        }
    }

    .fsocilLink{
        a{
            color:#333333;
            display: inline-block;
            margin-right:15px;
            font-size:17px;
        }
    }

    ul.nav{
        li{
            a{
                padding:5px 0;
                display:block;
            }
        }
    }


    .inputTxt{
        border-bottom:1px solid #b4b5b8;
        .input-group-text{
            padding-left:0px;
            border: 0 none;
            background-color: transparent;
        }
        .form-control{
            padding-left:0;
            padding-right:0px;
            border:0 none;
            background-color: transparent;
            font-size:12px;
        }
        .btn{
            padding: 0;
            border:0 none;
            text-transform: uppercase;
            color:#333333;
            font-size:14px;
            font-family: 'cerebri_sanssemibold';
        &:hover{
                background-color:transparent;
                color:$primary;
            }
        }
    }

}