@import "../../variable";


.avatarAction{
    a{
        font-size:13px;
        margin-right:15px;
        display: inline-block;
        color:#333333;
    }
}

.imageBox{
    figure{
        width:100px;
        height:100px;
        background-color:#333333;
        border-radius:100%;
    }
}

.profileEditCard{
    max-width:570px;
}