@import "../../variable";

.trackList{
    &.ofAlbum{
        .img figure{
            width:100px;
            height:100px;
        }
    }
    h6{
        margin-bottom:3px;
    }
 .count{
     font-size:24px;
     min-width:45px;
     color:$lightGray;
 }   
 .download-link{
    font-size:14px;
    margin-left: auto;
    a{
        color:$lightGray;
    }
 }
 p{
     font-size:11px;
     color:#666666;
     margin-bottom:0px;
 }
 .info{
     padding-right:15px;
 }
 .img figure{
    margin-right:15px;
    margin-bottom:0;
     height:60px;
     width:60px;
     border-radius:6px;
     position: relative;
     overflow: hidden;
     line-height: 60px;
     text-align: center;
     .icon{
         position: absolute;
         width:100%;
         height:100%;
         background-color: rgba(0,0,0,.5);
         left:0;
         top:0;
         line-height:1;
        &.play-icon::before{
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 10px 0 10px 20px;
            border-color: transparent transparent transparent $primary;
            content:'';
            left: 50%;
            margin-left: -10px;
            top: 50%;
            margin-top: -10px;
         }
        &.pause-icon::before{
            width:5px;
            height:20px;
            content:'';
            left:50%;
            background-color:$primary;
            position: absolute;
            margin-left:-10px;
            top:50%;
            margin-top:-12px;
         }
        &.pause-icon::after{
            width:5px;
            height:20px;
            content:'';
            left:50%;
            top:18px;
            background-color:$primary;
            position: absolute;
            margin-left:2px;
            top:50%;
            margin-top:-12px;
         }
     }
 }


 .play-icon{
     display: none;
 }
 &:hover{
     cursor: pointer;
     .download-link{
        a{
                color:$primary;
        }
     }
    

 .play-icon{
     display: block;
 }
 .count{
     span{
         display: none;
     }
 }
 h6{
     text-decoration: underline;
 }
 }
}

@media only screen and (max-width: 1700px) {
    .trackList .count{
        font-size: 17px;
    }
}

@media only screen and (max-width: 767px) {
    .trackList .count{
        min-width: 30px;
        width: 30px;
    }
}

body.dark-theme{
    .trackList p{
        color:#ebebeb;
    }
}
