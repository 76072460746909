@import "../../variable";


.albumGroup{
    width:100%;
    padding:10px;
    figure{
        
        padding-top:100%;
        border-radius:8px;
        background-color:$gray-200;
        background-size: cover;
    }
    h6{
        a{
            color:$dark;
        }
    }
    &:hover h6 a{
        color:$primary;
        text-decoration: underline;
    }
    .price{
        font-size:15px;
        font-family: "Adobe Garamond Premier Pro", Garamond, "Linux Libertine", "Times New Roman", Georgia, serif;
    }

}

.imakzv{
    margin:0 !important;
}