@import "../../variable";



.accordion{
    .card   { 
        background: #FFF;
        border-top: 0;
        border-left: 0;
        border-right: 0;
        border-bottom: 1px solid #f0f0f0 !important;
        .card-header{
            background: transparent;
            border-bottom: none;
            font-weight: 700;
            cursor: pointer
        }
    }
}
.ticket-summary{
    .ticket-number{
        font-size: 45px;
        font-weight: 700;
    }
    .ticket-number.tt a{
        color: #333;
    }
    .ticket-number.to a{
        color: #FF0000;
    }
    .ticket-number.tc a{
        color: green;
    }
}



.faqList{
    .card-header{
        padding-left:0px;
        padding-right:0px;
        font-weight: 500 !important ;
    }
    .collapse{
        padding-bottom:15px;
        div{
            p:first-child{
                margin-bottom:0px;
            }       
        }
    }
}


.recentTicket{
    padding:10px 0px 10px 0px;
    border-bottom:1px solid #e5e5e5;
    .t-sub{
        font-size:17px;
    }
    .d-flex{
        > div{
            padding-right:15px;
        }
    }
}

.page-item.active .page-link{
    background-color: $primary;
    border-color: $primary;   
}

.reply-msg{
    margin-bottom:15px;
        display: flex;
        flex-direction: column;
    .reply-content{
        padding:15px;
        background-color:#dddddd;
        border-radius:4px;
        display: inline-block;
        margin-right: auto; 
        max-width:80%;
    }
    .reply-date{
        font-size:13px;
        margin-top:5px;
    }

    &.reply-admin{
        align-items: flex-end;        
        .reply-content{
            margin-left: auto; margin-right:0px;
            background-color:$primary;
        }
    }
}

.pagin{
    .pagination{
        margin-bottom:0px;
    }
}

.dark-theme{
    .reply-msg{
        .reply-content{
            background-color:#fff;
            color: #333;
        }
        &.reply-admin{
            .reply-content{
                background-color: #000;
                color: #FFF;
            }
        }
    }
}