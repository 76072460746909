@import "../../variable";

ul.AccountNav{
    li{
        margin-right:35px;
        a{
            padding:10px 0px;
            display:block;
            color:#999999;
            border-bottom:2px solid #ffffff;
        }
        &:hover{
            a{
                text-decoration: none;
                color:$primary;
                border-bottom-color: $primary;
            }
        }
        a.active{
            border-bottom-color: $primary;
            color: $primary;
        }
    }
}


body.dark-theme{
    ul.AccountNav li a{
      border-bottom-color:#000000;
      &.active{
          border-bottom-color: $primary;
      }
    }
    .card-body{
        background: #2e2e2e;
    }
}