@import "../../variable";


.trackList.title{
 .info{
     border-left:1px solid #e1e2e7;
     padding-left:15px;
 }
 padding-bottom:15px;
 border-bottom:1px solid #e1e2e7;
 &:hover{
     border-bottom-color:#03060c;
 }
}