@import "_variable.scss";
@import "~bootstrap/scss/bootstrap";



@import "~slick-carousel/slick/slick.css"; 
@import "~slick-carousel/slick/slick-theme.css";

@import "./assets/scss/line-icon/lineicons.scss";
@import "./assets/scss/line-icon/icons.scss";


@font-face {
  font-family: 'cerebri_sanssemibold';
  src: url('./assets/fonts/cerebrisans-semibold-webfont.woff2') format('woff2'),
       url('./assets/fonts/cerebrisans-semibold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'cerebri_sansregular';
  src: url('./assets/fonts/cerebrisans-regular-webfont.woff2') format('woff2'),
       url('./assets/fonts/cerebrisans-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-family: 'cerebri_sanssemibold';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-bottom:101px;
}

.btn{
  text-transform: uppercase;
  padding: 10px 20px;
}
.btn.btn-sm{
  padding: 5px 10px;
  font-size: 13px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.play-icon{
  width:16px;
  height:18px;
  display:inline-block;
  position: relative;
  &::before{
    position: absolute;
    content:'';
    left:0;
    top:0;
    width: 0;
height: 0;
border-style: solid;
border-width: 9px 0 9px 16px;
border-color: transparent transparent transparent #f04d25;
  }
}

.sellAll-link{
  color:$primary;
  font-size:12px;
  letter-spacing:1.6px;
  text-transform: uppercase;
  i{
    font-size:10px;
  }
}

.sellAll-link.lg{
  font-size:18px;
  i{
    font-size:16px;
  }
}

.slick-arrow{
  width:24px;
  height:24px;
  border: 1px solid $primary;
  padding:0;
  background-color:$primary;
  text-indent:100px;
  overflow: hidden;
  box-shadow: none;
  position: absolute;
  top:-50px;
  right:34px;
  left: auto;
  z-index:9;
  border-radius:100%;
  &:hover{
    background-color:$bright;
    &::before{
      color:$dark;
    }
    box-shadow: none;
  }
  &::before{
    content:$lnir-chevron-left;
    display: inline-block;
    font: normal normal normal 1em/1 'LineIconsPro Regular';
    speak: none;
    text-transform: none;
    /* Better Font Rendering */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #fff;
    text-indent: 0;
    position: absolute;
    top: 0;
    left: -1px;
    font-size: 10px;
    width: 24px;
    height: 24px;
    line-height: 23px;
  }
  &.slick-next{
    right:0px;
    &::before{
      content:$lnir-chevron-right;
    }  
  }
  &.slick-disabled{
    background-color:$bright;
    border-color:#cccccc;
    &::before{
      color:$dark;
    }
  }
}

.sectionTitleHead{
  margin-bottom:30px;
}

.pagin{
  a{
    min-width:40px;
    height:40px;
    border:1px solid #dfdfdf;
    display:inline-block;  
    line-height:36px;
    text-align: center;
    padding:0 10px;
    border-radius:7px;
    font-size:14px;
    margin:0 2px;
    &:hover,&.active{
      background-color:$primary;
      border-color:$primary;
      color:#333333;
      text-decoration: none;
    }
  }
}




.sliderBoxFixed .slick-slider.slider .slick-list{
  margin:0 -10px;
}

// .sliderBox{
  // overflow-x: hidden;
  // width: 100%;
// }


.modal-content{
  padding:10px 15px;
}
.modal-footer,.modal-header{
  border: 0 none;  
}

.modal-header .close{
  font-size:33px;
}
.lineInput{
  border-bottom: 1px solid #dedede;
  .input-group-text{
    background-color:transparent;
    border: 0 none transparent;
  }
  .form-control{
    border: 0 none transparent;
  }
}

article,.card{
  ul,p{
    font-family: 'cerebri_sansregular';
    margin-bottom:30px;
    font-size: 14px;
    letter-spacing: .5px;
    line-height: 1.5;
  }
}

.linkAction{
  color: #0066ff;
}

.table thead th{
  border-top:0 none;
  border-bottom: 0 none;
}
.table tbody td{
  font-family: 'cerebri_sansregular';
  font-size: 14px;
  a{
    color: #333333;
    font-family: 'cerebri_sanssemibold';
    &:hover{
      color: #0066ff;
    }
  }
}
.table tbody tr:first-child td{
  border-top:0 none;
}
.table tbody tr:hover td{
  background-color: #f4f5f9;
}


h1.h1{
  font-size:60px;
}

.hightLightTxt{
  font-size:20px;
  font-family: 'cerebri_sansregular';
}

.cirleIcon{
  width:4px;
  height:4px;
  background-color:#333333;
  border-radius:100%;
  vertical-align:middle;
  display:inline-block;
}

.content-box{
  background-color:#f4f5f9;
  padding:40px;
  margin:0 -40px -20px -40px;
  border-top:1px solid #e5e5e5;
}

.cardMark{
  text-transform: uppercase;
  color:#999999;
  letter-spacing:.1px;
  font-size:13px;
}


@media only screen and (max-width: 1700px) {
  h3{
      font-size: 20px;
  }
}
.DatePicker__input{
  background-image: url(assets/img/dateicon.png) !important;
  background-repeat: no-repeat !important;
  background-position: 10px center !important;
  padding-left: 10px !important;
  cursor: pointer !important;
}

//bootstrap variable customization ----------------



//Button


//Global Theme ----------------



body.dark-theme{
  .content-box{
    background-color:#2a2a2a;
    border-top-color:#3e3e3e;
  }
  .text-dark{
    color: $bright !important;
  }
  a{
    color: $bright;
  }
}

