.pointer{
    cursor: pointer !important;
}
.loader {
    color: #FFF;
    position: absolute;
    width: 100%;
    height: 100%;
    background: #000;
    z-index: 999;
    top: 0;
    left: 0;
    opacity: 0.5;
}