@import "../../variable";




ul.planInfoList{
    display:flex;
    flex-wrap: wrap;
    padding-left:0;
    list-style-position: inside;
    li{
        width:50%;
        padding-right:10px;
        font-family: 'cerebri_sanssemibold';
        label{
            color:#666666;
            font-family: 'cerebri_sansregular';
        }

    }
}

.plan-price{
    sup{
        color:#669900;
        font-size:24px;
        position: relative;
        top:-30px;
    }
    span{
        color:#669900;
        font-size:60px;
        font-weight: bold;
    }
    sub{
        font-size:18px;
        color: #999999;
        letter-spacing:1px;
    }
}

.feateureList{
    list-style: none;
    padding: 0px;
    li{
            font-size:17px;
            margin-bottom:10px;
            i{
                color: #669900;
            }
    }
}

.subscribeInput{
    background-color:#f6f7fb;
    border:1px solid #d7d9df;
    .form-control{
        background-color: transparent;
        border:0 none;
    }
    .btn{
        border:0 none;
        font-weight: bold;
        color: #444444;
    }
}
.payment-type{
    .form-group{
        label{
            width: 100% !important;
        }
    }
}

.planBox{
    max-width:360px;
    margin-left:20px;
    margin-right:20px;
    margin-top:40px;
    background-color: $bright;
    position: relative;
    padding-bottom: 50px;
    .action {
        position: absolute;
        width: 90%;
        bottom: 25px;
    }
}