@import "../../variable";

.infoInstuction{
    font-size:15px;
    line-height:1.2;
    a{
        text-decoration: underline;
        color:#333
    }
}
.popup-form{
    .input-group{
        .invalid-feedback{
            padding-left: 50px;
        }
    }
}