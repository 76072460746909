@import "../../variable";

.filterLink{
    a{
        font-size:12px;
        color: #999999;
        display: inline-block;
        padding:5px 10px;
        &.active{
            color:#ffa800
        }
    }
}



.genresList{
    ul{
        border-top:1px solid #d9d9d9;
        margin-top:15px;
        list-style: none;
        padding:15px 0 0 0;
        li{
            a{
                display:flex;
                font-size:14px;
                justify-content: space-between;
                padding:5px 0;
                font-weight:300;
                color:#000000;    
                &:hover{
                    color: $primary;
                    text-decoration: none;
                }
            }
        }
    }
}